import React from "react";
import CourseImage from "../../assets/img/Img.png";
import presonImage4 from "../../assets/img/presonImage4.svg";
import star from "../../assets/img/starGreen.svg";

function CourseDetails(props) {

  return (
    <div>
      {/* Top  */}
      <div className="flex border">
        <div className="w-1/3">
          <img className="h-full w-full" src={CourseImage} />
          <h1> {console.log(props)}</h1>
        </div>
        <div className="w-2/3 ml-1">
          <h1 className="font-bold">
            Level 1&2 Award in Food Safety in Catering
          </h1>
          <div className="flex mt-3">
            {/* img */}
            <div>
              <img src={presonImage4}></img>
            </div>
            {/* name and description */}
            <div className="ml-3">
              <p className="text-base">Roynaldo Jr</p>
              <p className="text-sm text-gray-600"> Data Science</p>
            </div>
          </div>

          <hr className="m-2 bg-gray-600"></hr>

          <div className="grid grid-cols-2">
            <div className="flex">
              <h1 className="text-xl font-bold">180</h1>
              <p className="mt-1">/SAR</p>
            </div>
            <div className="justify-self-end flex">
              <div className="mr-1 mt-1">
                <img src={star} />
              </div>
              <div>
                <strong>4.91</strong>(98)
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Crds */}

      <div className="mx-5">
        <div className="grid grid-cols-2 gap-5">
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Booking Type
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="Corporate"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
        </div>
        <div>
          <div className="">
            <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
              Email
            </div>
            <div className="my-2 flex rounded border border-[#CFCFCF] bg-white p-1">
              <input
                name="email"
                placeholder="example@gmail.com"
                className="w-full appearance-none p-1 px-2 text-gray-800 outline-none"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CourseDetails;
