import Global from "../../assets/svgs/Global";
import SmallCalendarSvg from "../../assets/svgs/SmallCalendar";
import { Swiper, SwiperSlide } from "swiper/react";
import './styles.css';
import moment from "moment";
import 'swiper/css';
// import 'swiper/css/pagination';

const PastExams = ({pastExams}) => {
    console.log('pastExams: ',pastExams)
    return (
        <div>
            {/* <h1>sss {pastExams.length}</h1> */}
           
            
            <h3 className='font-semibold text-xl'>Past Exams</h3>
            {pastExams.length > 0 ? 
            <>
            <div className="mt-4">
                {/* <Swiper
                slidesPerView={3}
                spaceBetween={0}
                breakpoints={{
                    1300 : {
                        slidesPerView : 3
                    } ,
                    1000 : {
                        slidesPerView : 2 ,
                    } ,
                    768 : {
                        slidesPerView : 1
                    } ,
                    600 : {
                        slidesPerView : 2
                    } ,
                    200 : {
                        slidesPerView : 1
                    }
                }}
                className="pastExamsSwiper"
                >
                    {
                        pastExams.map((item , i) => (
                            <SwiperSlide>
                                <div className='flex items-center' >
                                    <div className="flex flex-col py-1 px-8 rounded-lg border border-dashed border-primary bg-blue-100 text-primary items-center justify-center">
                                        <div>
                                            <SmallCalendarSvg />
                                        </div>
                                        <p className="text-sm mt-1">{moment(item?.schedule?.createdAt).format('MMM')}</p>
                                        <p className="text-xl font-bold">{moment(item?.schedule?.createdAt).format('DD')}</p>
                                    </div>
                                    <div className="bg-pure rounded-lg py-4 px-6 flex items-center gap-4 border border-gray-200">
                                        <div className="text-grayText font-bold text-xl">
                                        {parseFloat(item?.studentBooking[0]?.score) === -1 ? 'Pending' : ((parseFloat(item?.studentBooking[0]?.score) / parseFloat(item?.schedule?.course?.examData?.fullMark)) * 100).toFixed(2) + '%'}
                                        </div>
                                        <div className="w-1 h-10 bg-primary rounded-md"></div>
                                        <div className="flex flex-col gap-1">
                                            <p className="text-sm text-grayText">{item?.schedule?.course?.name}</p>
                                            <div className="flex items-center gap-1">
                                                <div>
                                                    <Global />
                                                </div>
                                                <span className="text-[10px] text-grayText">{item?.schedule?.course?.language?.nameEn}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper> */}

                <div className="grid gird-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1 overflow-x-auto">
                {pastExams.map((item , i) => ( 
                <div className='flex items-center my-2 w-full' >
                   
                                    <div className="flex flex-col py-1 px-8 rounded-lg border border-dashed border-primary bg-blue-100 text-primary items-center justify-center">
                                        <div>
                                            <SmallCalendarSvg />
                                        </div>
                                        {/* <p className="text-sm mt-1">{moment(item?.schedule?.createdAt).format('YYYY-MM-DD')}</p> */}
                                        <p className="text-sm mt-1">{moment(item?.schedule?.createdAt).format('MMM')}</p>
                                        <p className="text-xl font-bold">{moment(item?.schedule?.createdAt).format('DD')}</p>
                                    </div>
                                    <div className="bg-pure rounded-lg py-4 px-6 flex items-center gap-4 border border-gray-200 w-4/6">
                                        <div className="text-grayText font-bold text-xl">
                                        {parseFloat(item?.studentBooking[0]?.score) === -1 ? 'Pending' : ((parseFloat(item?.studentBooking[0]?.score) / parseFloat(item?.schedule?.course?.examData?.fullMark)) * 100).toFixed(2) + '%'}
                                        </div>
                                        <div className="w-1 h-10 bg-primary rounded-md"></div>
                                        <div className="flex flex-col gap-1">
                                            <p className="text-sm text-grayText">{item?.schedule?.course?.name}</p>
                                            <div className="flex items-center gap-1">
                                                <div>
                                                    <Global />
                                                </div>
                                                <span className="text-[10px] text-grayText">{item?.schedule?.course?.language?.nameEn}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                ))}
                </div>
            </div>
            </>
            :
            <div className="my-4">
                <h1>Past Exams Not Found.</h1>
            </div>
            }
            
        </div>
    )
}

export default PastExams
