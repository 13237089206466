import React, {useState, useEffect} from 'react'
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
  Card, 
  CardBody
} from '@windmill/react-ui';
import axios from 'axios'
import config from '../../config';

function InfoCard({ title, value, children: icon, type }) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [dailySummary, setDailySummary] = useState([])

  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

  async function getDailySummary() {
    let data;
    data = await axios.get(config.API_URL + '/finance/daily-closing/summary');
    console.log(data.data);
    setDailySummary(data.data);
    console.log(data.data)
  }

  useEffect(() => {
    getDailySummary()
  },[])

  return (
    <>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <ModalHeader>Total revenue</ModalHeader>
        <ModalBody>
          {/* <p className='font-bold my-3'></p> */}
          <>
        <div className='grid grid-cols-2'>
          <div>
            <h1 className='text-black'>Type</h1>
          </div>
          <div>
            <h1 className='text-black'>Amount </h1>
          </div>
        </div>
        
        <div className='grid grid-cols-2 mt-5'>
          <div>
            <h1 className='text-gray-600 my-2'>Total Revenue:</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>{dailySummary.totalRevenue} /SAR</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>Total Cash Revenue:</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>{dailySummary.cashRevenue} /SAR</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>Total Cards Revenue:</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>{dailySummary.creditRevenue} /SAR</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>Total Advance Payment Revenue:</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>{dailySummary.advancedRevenue} /SAR</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>Previous Total Revenue:</h1>
          </div>
          <div>
            <h1 className='text-gray-600 my-2'>{dailySummary.previousRevenue} /SAR</h1>
          </div>
        
         <div>
         <br/>
            <h1 className='text-black text-lg'>Total:</h1>
          </div>
          <div>
          <br/>
            <h1 className='text-black text-lg'>{dailySummary.totalRevenue + dailySummary.advancedRevenue + dailySummary.previousRevenue} /SAR</h1>
          </div>
        </div>
          </>

       


        



          <br />

        </ModalBody>
        <ModalFooter>

        </ModalFooter>
      </Modal>

    <Card className="flex">
      <CardBody className="flex items-center">
        {icon}
        <div>
          <p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">{title}</p>
          <p className="text-lg font-semibold text-gray-700 dark:text-gray-200">{value}</p>
        </div>
      </CardBody>
      {type == 'View' ? 
      <div className="place-self-center ml-14">
      <a className='underline text-gray-400 text-xs cursor-pointer' onClick={() => openModal()}>View Details</a>
      </div>
      :
      <></>
      }
    </Card>
    </>
  )
}

export default InfoCard
