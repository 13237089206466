import React, { useState, useEffect } from 'react';

import CourseImage from '../../assets/img/CourseImage.svg';
import clockhistory from '../../assets/img/clock-history.svg';
import user from '../../assets/img/user.svg';
import book from '../../assets/img/book.svg';
import presonImage4 from '../../assets/img/presonImage4.svg';
import { useNavigate } from 'react-router-dom';
import Search from '../../components/util/Search';

const data = [
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 2,
    name: 'name',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
  {
    id: 1,
    name: 'Level 1&2 Award in Food ...',
  },
];

function Booking() {
  const navigate = useNavigate();
  const [query, setQuery] = useState('');

  return (
    <>
      <div className="bg-[#FFFFFF] my-8 border-sm p-4">
        <div className="my-5 grid grid-cols-1 md:grid-cols-2 ">
          {/* 
            // Search Bar  DONE

            // Date 

            // Type


            // Branch

            //lang

            //level

          */}
          <div>
            <input
              type="text"
              placeholder="Search Course Name"
              className="border border-gray-200 h-16 rounded p-2"
              onChange={(e) => setQuery(e.target.value)}
            />
            <input
              type="date"
              className="mx-3 border border-gray-200 h-16  rounded p-2"
              // placeholder="dd"
            />
          </div>

          <div className="flex my-2">
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Type</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">Online</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Branch</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">All</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Lang</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">All</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
            <div className="mx-2 border border-gray-200 rounded">
              <label className="text-gray-700 ">Level</label>
              <select
                className="mx-1"
                onChange={(e) => setQuery(e.target.value)}
              >
                <option value="Corporate">All</option>
                <option value="Student">Z-A</option>
              </select>
            </div>
          </div>
        </div>

        <div className="my-6 w-1/2">
          <div className="max-w-screen-xl px-4 py-3 mx-auto md:px-6">
            <div className="flex items-center">
              <ul className="flex flex-row mt-0 mr-6 space-x-16 text-lg font-medium">
                <li>
                  <p className="active:underline" aria-current="page">
                    Courses
                  </p>
                </li>
                <li>
                  <p className="text-[#9C9CA4] hover:underline">Exams</p>
                </li>
                <li>
                  <p className="text-[#9C9CA4] hover:underline">Both</p>
                </li>
              </ul>
            </div>
          </div>
          <hr></hr>
        </div>
        <div className="grid grid-cols-1  sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Card */}
          {data
            .filter((course) => course.name.toLowerCase().includes(query))
            .map((item, index) => (
              <div className="" key={index}>
                {/* IMAGE */}
                <div>
                  <img src={CourseImage} />
                </div>

                {/* TITLE Des */}
                <div className="flex mt-3">
                  <div>
                    <h1 className="font-bold ">{item.name}</h1>
                    <p className="text-gray-700 text-sm">
                      The WordPress ope source,free software project is the
                      communit...
                    </p>
                  </div>
                  <div className=" bg-[#E1EEED] rounded px-2 my-2">
                    <h4 className=" text-[#046F6D] ">Jan</h4>
                    <h1 className=" font-bold  text-[#046F6D] ">24</h1>
                  </div>
                </div>
                <hr className="my-2 bg-gray-800 " />
                {/* Hour student */}

                <div className="flex items-center">
                  <div className="grid grid-cols-3 ml-12 gap-5">
                    <div>
                      <div>
                        <img src={clockhistory} />
                      </div>
                      <div>
                        <p className="text-xs text-gray-800"> 100 Hour</p>
                      </div>
                    </div>

                    <div>
                      <div className="mt-1">
                        <img src={user} />
                      </div>
                      <div>
                        <p className="text-xs text-gray-800 mt-0.5">
                          {' '}
                          20 Student
                        </p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <img src={book} />
                      </div>
                      <div>
                        <p className="text-xs text-gray-800"> 10 Session</p>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="mt-1 mb-1 bg-gray-800" />

                {/* profile image */}
                <div className="mt-3">
                  <div className="flex">
                    <div className="mr-2">
                      <img src={presonImage4} />
                    </div>
                    <div>
                      <p className="font-bold  text-sm mt-1">Roynaldo Jr</p>
                      <p className="text-gray-900  text-xs mt-1">
                        Data Science
                      </p>
                    </div>
                  </div>
                </div>

                {/* Button */}
                <div className="grid grid-cols-2 gap-3 mt-3">
                  <div>
                    <button
                      onClick={() => [
                        navigate(`/corporate/courses/${item.id}`),
                      ]}
                      className="bg-[#046F6D] text-white font-bold p-5 rounded-lg w-full"
                    >
                      {' '}
                      Book Now{' '}
                    </button>
                  </div>

                  <div className="flex mt-5 ml-5">
                    <h1 className="flex font-bold text-2xl">180 </h1>
                    <p className="flex mt-2">/SAR</p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default Booking;
