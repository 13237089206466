import React, { Suspense, lazy, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './pages/Login/Login';
// import Login2 from './pages/Login/Auth';
import CorporateRegister from './pages/Register/CorporateRegister/CorporateRegister';
import TrainingCenterRegister from './pages/Register/TrainingCenterRegister/TrainingCenterRegister';
import MedicalCenterRegister from './pages/Register/MedicalCenterRegister/MedicalCenterRegister';
import Dashboard from './pages/Dashboard/Dashboard';
import Corporate from './pages/Dashboard/Corporate';
import Student from './pages/Dashboard/Student';
import NewCorporate from './pages/Dashboard/NewCorporate';
import Booking from './pages/CorporateDashboard/Booking';
import CourseDetails from './pages/CorporateDashboard/CourseDetails';
import CourseDetailsConfirm from './pages/CorporateDashboard/CourseDetailsConfirm';
import Wizard from './components/wizard/Wizard';
import Training from './pages/Dashboard/Training';
import BookingRequests from './pages/Dashboard/Booking';
import BookingManagement from './pages/Dashboard/BookingManagement';
import BookingRetake from './pages/Dashboard/BookingRetake';
import Sidebar from "./components/global/sidebar";

import RetakeExam from "./pages/Dashboard/retakeExam";
import BookRetake from "./pages/Dashboard/bookRetake";
import BookRetakePayment from './pages/Dashboard/bookRetake/BookRetakePayment'; //"./pages/bookRetake/BookRetakePayment";
import BookRetakeSucceed from "./pages/Dashboard/bookRetake/BookRetakeSucceed";
import BookingManagementRetake from "./pages/Dashboard/BookingManagementRetake";

import AmanaInvoice from './pages/Dashboard/amanaInvoice/AmanaInvoice';
import AmanaBatch from './pages/Dashboard/amanaInvoice/AmanaBatch';
// import BranchLedger from './pages/Dashboard/LedgerSetup/BranchLedger';
// import BranchList from './pages/Dashboard/LedgerSetup/BranchList';

import DailyClosing from './pages/Dashboard/DailyClosing';
import AddClosing from './pages/Dashboard/DailyClosing/AddClosing';
import UploadClosing from './pages/Dashboard/DailyClosing/UploadClosing';
import FinishClosing from './pages/Dashboard/DailyClosing/FinishClosing';

import RefundPayment from './pages/Dashboard/RefundPayment';

import Protected from './Protected.js';

import Auth from './pages/Dashboard/Auth'

import Medical from './pages/Dashboard/Medical';


const Admin = lazy(() => import("./pages/Dashboard/Admin"));
const CorporateAdmin = lazy(() => import("./pages/CorporateDashboard/Admin"));
const StudentRegister = lazy(() => import("./pages/Register/StudentRegister/StudentRegister"));

const StudentRegisterWizard = lazy(() => import("./components/wizard/pages/Student"));
const CorporateRegisterWizard = lazy(() => import("./components/wizard/pages/Corporate"));
const MedicalRegisterWizard = lazy(() => import("./components/wizard/pages/Medical"));
const TrainingRegisterWizard = lazy(() => import("./components/wizard/pages/Training"));

const StudentRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Student"));
const CorporateRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Corporate"));
const MedicalRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Medical"));
const TrainingRegisterWizardAdminInside = lazy(() => import("./components/wizardNew/pages/Training"));

const BranchManagement = lazy(() => import("./pages/Dashboard/BranchManagement"));
const UserManagement = lazy(() => import("./pages/Dashboard/UserManagement"));
const CourseManagement = lazy(() => import("./pages/Dashboard/CourseManagement"));
const AddBranch = lazy(() => import("./pages/Dashboard/AddBranch"));
// const EditBranch = lazy(() => import("./pages/Dashboard/EditBranch"));
// const AddCourse = lazy (() => import("./components/wizardAdmin/pages/AddCourse"));
// const AddCourse = lazy (() => import("./pages/Dashboard/Course/addNewCourse/index"));

const AddNewCourse = lazy(() => import("./pages/Dashboard/Course/addNewCourse"));
const CourseExam = lazy(() => import("./pages/Dashboard/Course/addNewCourse/CourseExam"));
const CourseSessions = lazy(() => import("./pages/Dashboard/Course/addNewCourse/CourseSessions"));

const AddUser = lazy(() => import("./pages/Dashboard/AddUser"));
// const EditUser = lazy(() => import("./pages/Dashboard/EditUser"));
const CorporateProfile = lazy(() => import("./components/profile/Wizard"));
const InstructorProfile = lazy(() => import("./components/profile/Wizard"));
const BranchProfile = lazy(() => import("./components/profile/Wizard"));
const StudentsProfile = lazy(() => import("./components/profile/Wizard"));
const TraningProfile = lazy(() => import("./components/profile/Wizard"));
const MedicalProfile = lazy(() => import("./components/profile/Wizard"));
const UsersProfile = lazy(() => import("./components/profile/Wizard"));
const AddClassToBranch = lazy(() => import("./pages/Dashboard/AddClassToBranch"));
const Instructors = lazy(() => import("./pages/Dashboard/Instructors"));
const Scheduling = lazy(() => import("./pages/Dashboard/Scheduling"));
const FeesSetup = lazy(() => import("./pages/Dashboard/FeesSetup"));
// const EditFees =  lazy(() => import("./pages/Dashboard/EditFees"));
const AddFeesSetup = lazy(() => import("./pages/Dashboard/AddFeesSetup"));
const HealthCheck = lazy(() => import("./pages/Dashboard/HealthCheck"));
const AllPayment = lazy(() => import("./pages/Dashboard/AllPayment"));
const AllPaymentStepOne = lazy(() => import("./pages/Dashboard/AllPaymentStepOne"));

const NewBook = lazy(() => import("./pages/Dashboard/Book/NewBook"));
const NewBookEnd = lazy(() => import("./pages/Dashboard/Book/NewBookEnd"));

const AddExam = lazy(() => import("./pages/Dashboard/AddExam"));
const AddTraning = lazy(() => import('./pages/Dashboard/AddTraning'))

const RefundCorporate = lazy(() => import("./pages/Dashboard/RefundCorporate"))

// ledgers
const BranchList  = lazy(() => import("./pages/Dashboard/LedgerSetup/BranchList"));
const BranchLedger = lazy(() => import("./pages/Dashboard/LedgerSetup/BranchLedger"));

const Score = lazy(() => import("./pages/Dashboard/Score"));

const RequestManagement = lazy(() => import("./pages/Dashboard/RequestManagement"))

// Advance Payments
// const AdvanceList  = lazy(() => import("./pages/Dashboard/AdvancePayments/AdvanceList"));
// const AdvanceNew = lazy(() => import("./pages/Dashboard/AdvancePayments/AdvanceNew"));

const StudentRegisterWizardAdmin = lazy(() =>
  import('./components/wizardAdmin/pages/Student'),
);
const CorporateRegisterWizardAdmin = lazy(() =>
  import('./components/wizardAdmin/pages/Corporate'),
);

const StudentRegisterionPage = lazy(() =>
  import('./pages/Dashboard/StudentRegisterionPage')
)
const StudentRegistrationPage = lazy(() =>
  import ('./pages/Dashboard/StudentRegistrationPage')

)

function GlobalDebug (debugOn, suppressAll) {
  var savedConsole = console;
    var suppress = suppressAll || false;
    if (debugOn === false) {
      // supress the default console functionality
      console = {};
      console.log = function () {};
      // supress all type of consoles
      if (suppress) {
        console.info = function () {};
        console.warn = function () {};
        console.error = function () {};
      } else {
        console.info = savedConsole.info;
        console.warn = savedConsole.warn;
        console.error = savedConsole.error;
      }
    } else {
      console = savedConsole;
    }
  }

function App() {
  useEffect(() => {
    localStorage.removeItem('theme');
  }, []);

  // useEffect(() => {
  //   (process.env.NODE_ENV === "production" || true ||
  //    process.env.REACT_APP_ENV === "STAGING") &&
  //     GlobalDebug(false,true);
  // }, []);

  return (
    <>
    <Suspense fallback={<h3>Loading...</h3>}>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/login" element={<Login />}></Route> */}
          <Route path="/login" element={<Login />}></Route>
          <Route path="/register" element={<Login />}></Route>
          <Route path="/Auth/:token" element={<Auth />}></Route>
          {/* <Route path="/student-register2" element={<StudentRegister />}></Route>
          <Route path="/corporate-register2" element={<CorporateRegister />}></Route> */}

          {/* <Route path="/student-register" element={<StudentRegisterionPage />}></Route> */}

          <Route path="/student-register" element={<StudentRegisterWizard />}></Route>

          <Route path="/training-register" element={<TrainingRegisterWizard />}></Route>
          <Route path="/medical-register" element={<MedicalRegisterWizard />}></Route>
          <Route path="/corporate-register" element={<CorporateRegisterWizard />}></Route>
          {/* <Route
            path="/training-center-register"
            element={<TrainingCenterRegister />}
          ></Route>
          <Route
            path="/medical-center-regixster"
            element={<MedicalCenterRegister />}
          ></Route> */}
          <Route path="/admin" element={<Protected component={Admin} />} >


            <Route path="dashboard" element={<Protected component={Dashboard}  />}></Route>
            {/* <Route path="staff"></Route> */}
            {/* permission={{action:'create',resourse: 1}} */}
            <Route path="corporate" element={<Protected permission={['read_4']} component={Corporate} />}></Route>
            <Route path="corporate/profile" element={<Protected permission={['read_4']} component={CorporateProfile} />}></Route>
            <Route path="corporate/add-new" element={<Protected permission={['create_4']} component={CorporateRegisterWizard} />}></Route>
            <Route path="students" element={<Protected permission={['read_3']} component={Student} />}></Route>
            <Route path="students/add-new" element={<Protected permission={['create_3']} component={StudentRegisterWizard} />}></Route>
            <Route path="training-center/add-new" element={<Protected permission={['create_4']} component={TrainingRegisterWizard} />}></Route>
            <Route path="medical-center/add-new" element={<Protected permission={['create_4']} component={MedicalRegisterWizard} />}></Route>
            <Route path="medical-center" element={<Protected permission={['read_4']} component={Medical} />}></Route>
            <Route path="training-center" element={<Protected permission={['read_4']} component={Training} />}></Route>
            <Route path="booking-requests" element={<Protected permission={['read_9']} component={BookingRequests} />}></Route>
            <Route path="booking-management" element={<Protected permission={['read_9']} component={BookingManagement} />}></Route>
            <Route path="request-management" element={<Protected permission={['read_2']} component={RequestManagement} />}></Route>
            <Route path="booking-retake-exam" element={<Protected permission={['create_9']} component={BookingRetake }/>}></Route>
            <Route path="branch-management" element={<Protected permission={['read_2']} component={BranchManagement }/>}></Route>
            <Route path="course-management" element={<Protected permission={['read_6']} component={CourseManagement }/>}></Route>
            <Route path="add-branch" element={<Protected permission={['create_2']} component={AddBranch }/>}></Route>
            {/* <Route path="edit-branch/:id" element={<Protected permission={['edit_2']} component={EditBranch }/>}></Route> */}
            {/* <Route path="add-course" element={<AddCourse />}></Route> */}

            <Route path='course-management-test'  element={<Protected permission={['read_6']} component={AddNewCourse }/>} />
            <Route path='add-new-course'  element={<Protected permission={['create_6']} component={AddNewCourse }/>} />
            <Route path='add-new-course/sessions'  element={<Protected permission={['create_6']} component={CourseSessions }/>} />

            <Route path='add-new-course/exam'  element={<Protected permission={['create_6']} component={CourseExam }/>} />
            <Route path="add-user"  element={<Protected permission={['create_1']} component={AddUser }/>}></Route>
            {/* <Route path="edit-user/:id"  element={<Protected permission={['edit_1']} component={EditUser }/>}></Route> */}
            <Route path="add-class-to-branch/:id"  element={<Protected permission={['create_2']} component={AddClassToBranch }/>}></Route>
            <Route path="branches/profile/:id"  element={<Protected permission={['read_2']} component={BranchProfile }/>}></Route>
            <Route path="students/profile/:id"  element={<Protected permission={['read_3']} component={StudentsProfile }/>}></Route>
            <Route path="training-center/profile/:id"  element={<Protected permission={['read_4']} component={TraningProfile }/>}></Route>
            <Route path="medical-center/profile/:id"  element={<Protected permission={['read_4']} component={MedicalProfile }/>}></Route>
            <Route path="instructor/profile/:id"  element={<Protected permission={['read_1']} component={InstructorProfile }/>}></Route>
            <Route path="users/profile/:id"  element={<Protected permission={['read_1']} component={UsersProfile } />}></Route>
            <Route path="user-management" element={<Protected permission={['read_1']} component={UserManagement} />}></Route>
            <Route path="instructors" element={<Protected permission={['read_1']} component={Instructors} />}></Route>
            <Route path="Scheduling" element={<Protected permission={['read_8']} component={Scheduling} />}></Route>
            <Route path="fees-setup" element={<Protected permission={['read_10']} component={FeesSetup} />}></Route>
            <Route path="add-fees-setup" element={<Protected permission={['create_10']} component={AddFeesSetup} />}></Route>
            {/* <Route path="edit-fees/:id" element={<Protected permission={['edit_10']} component={EditFees} />}></Route> */}

            
            <Route path="all-payments"element={<Protected permission={['read_10','read_17']} component={AllPayment} />}></Route>
            <Route path="all-payments/1" element={<Protected permission={['read_10','read_17']} component={AllPaymentStepOne} />}></Route>
            <Route path="health-check" element={<Protected permission={['read_20']} component={HealthCheck} />}></Route>
            <Route path="new-book" element={<Protected permission={['create_9']} component={NewBook} />}></Route>
            <Route path="new-book/end" element={<Protected permission={['create_9']} component={NewBookEnd} />}></Route>
            <Route path="add-exam" element={<Protected permission={['create_8']} component={AddExam} />}></Route>
            <Route path="add-training" element={<Protected permission={['create_8']} component={AddTraning}/>} ></Route>
            <Route path="daily-closing" element={<Protected permission={['read_10','read_17']} component={DailyClosing} />}></Route>
            <Route path="daily-closing/add-closing" element={<Protected permission={['create_10','create_17']} component={AddClosing} />}></Route>
            <Route path="daily-closing/add-closing/upload" element={<Protected permission={['create_10','create_17']} component={UploadClosing} />}></Route>
            <Route path="daily-closing/add-closing/finish" element={<Protected permission={['create_10','create_17']} component={FinishClosing} />}></Route>

            <Route path="refund-corporate" element={<Protected permission={['create_10','create_17','read_10','read_17']} component={RefundCorporate} />}></Route>
            <Route path="refund-payment" element={<Protected permission={['create_10','create_17','read_10','read_17']} component={RefundPayment} />}></Route>


            <Route path='retake-exam' element={<Protected permission={['read_9']} component={RetakeExam }/>} ></Route>
            <Route path='retake-exam/book-retake' element={<Protected permission={['create_9']} component={BookRetake }/>} ></Route>
            <Route path='retake-exam/book-retake/payment' element={<Protected permission={['create_9']} component={BookRetakePayment }/>} ></Route>
            {/* <Route path='retake-exam/book-retake/succeed' element={<BookRetakeSucceed />} ></Route> */}
            <Route path='booking-management/book-details/:id' element={<Protected permission={['read_9']} component={BookingManagementRetake }/>} ></Route>

            {/* BranchLedger */}
            <Route path='ledger-setup' element={<Protected permission={['read_10']} component={BranchList }/>} ></Route>
            <Route path='ledger-setup/:id' element={<Protected permission={['read_10']} component={BranchLedger }/>} ></Route>

              {/* AdvancePayment */}
            {/* <Route path='advanced-payment' element={<Protected permission={['read_10']} component={AdvanceList }/>} ></Route>
            <Route path='advanced-payment/new' element={<Protected permission={['create_10']} component={AdvanceNew }/>} ></Route> */}

            <Route path='scores' element={<Protected permission={['read_19']} component={Score }/>} ></Route>
            <Route path='amana-invoice' element={<Protected permission={['read_10','read_18']} component={AmanaInvoice }/>} ></Route>
            <Route path='amana-invoice/:id' element={<Protected permission={['read_10','read_18']} component={AmanaBatch }/>} ></Route>



          </Route>
          <Route path="/corporate" element={<CorporateAdmin />} >
            <Route path="dashboard" element={<Dashboard />}></Route>
            <Route path="courses" element={<Booking />}></Route>
            <Route path="courses/:id" element={<CourseDetails />}></Route>
            <Route path="courses/confirm/:id" element={<CourseDetailsConfirm />}></Route>
          </Route>
          <Route path="/" element={<Login />}></Route>
          <Route path="*" element={<Login />}></Route>
        </Routes>
      </BrowserRouter>
      </Suspense>
    </>
  );
}

export default App;

