import React, {useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../../config';
import moment from 'moment';

function AddClosing() {

  const navigate = useNavigate();
  const [tableBody, setTableBody] = useState([])
  const [allData, setAllData] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [total, setTotal] = useState(0);

  const handleChangeWithName = (value, name) => {
    console.log(value, name);
    setAllData({...allData, [name]: value})
    console.log(allData)
  }

  async function getNewClosing() {
    let data;
    data = await axios.get(config.API_URL + '/finance/closing/new')
    console.log(data.data);
    setTableBody(data.data);
  }

  async function submit() {
    let data;
    let finalData = allData;
    finalData.cashRevenue = allData.cashRevenue || 0
    finalData.creditRevenue = allData.creditRevenue || 0
    finalData.transactions = selectedStudents;
    data = await axios.post(config.API_URL + '/finance/closing/new' ,finalData ).then((res) => {
      navigate('/admin/daily-closing')
    }).catch((error) => {
      console.log(error)
    })
    console.log(data.data);
  }

  useEffect(() => {
    getNewClosing();
  },[])


  return (
    <div className='bg-white mt-16'>
      <div className='flex flex-cols p-5 gap-4' >
        <div >
          <span>Total cash revenue</span>
          <div className='mt-3'>
            <input
              className="border rounded p-3  w-full text-sm"
              name="cashRevenue"
              placeholder="0"
              type="text"
              value={allData['cashRevenue']}
              onChange={(e) => handleChangeWithName(e.target.value, 'cashRevenue')}
            />
          </div>
        </div>
        <div >
          <div>
            <span>Total cards revenue</span>
          </div>
          <div className='mt-3'>
            <input
              className="border rounded p-3  w-full text-sm"
              name="creditRevenue"
              placeholder="0"
              type="text"
              value={allData['creditRevenue']}
              onChange={(e) => handleChangeWithName(e.target.value, 'creditRevenue')}
            />
          </div>
        </div>
      </div>


      {/* Table */}
      <div className='p-5'>
        <div className="overflow-x-auto mt-10">
          <table className="w-full text-sm text-left overflow-x-auto whitespace-nowrap">
            <thead className="md:text-sm text-[13px] bg-gray-100 text-dark">
              <tr>
                <th scope="col" className="sm:px-2 px-1 py-4">

                </th>
                <th scope="col" className="sm:px-2 px-1 py-4">
                  Transaction type
                </th>
                <th
                  scope="col"
                  className="sm:px-2 px-1 py-4 lg:flex  hidden"
                >
                  cash amount
                </th>
                <th
                  scope="col"
                  className="sm:px-2 px-1 py-4 md:table-cell hidden"
                >
                  date
                </th>
              </tr>
            </thead>
            <tbody>
              {tableBody?.map((item, i) => (

              <>
                <tr className="bg-white border-b border-t  bg-pure md:text-sm text-[13px]">
                  <td
                    scope="row"
                    className="sm:px-2 px-1 py-3 "
                  >
                    <div className="items-center ">
                      <input type="checkbox" className='accent-[#046F6D]'  onChange={(e) =>{
                        let tempSession = selectedStudents;
                        // console.log('e.target.value: ',e.target.checked)
                        let tempTotal = total;
                        if(e.target.checked){
                          tempSession?.push(item?.entries[0]?.transactionId);
                          tempTotal += parseFloat(item?.entries[0]?.amount);
                        } else {
                          tempSession = tempSession?.filter((sc) => item?.entries[0]?.transactionId !== sc );
                          tempTotal -= parseFloat(item?.entries[0]?.amount);
                        }
                        setTotal(tempTotal)
                        setSelectedStudents(tempSession);
                      }}/>
                    </div>
                  </td>
                  <td className="sm:px-2 px-1 py-3 text-dark">
                    <div className="flex flex-col">
                      <p>{/* {item?.sName} */}</p>
                      <span className="text-xs">
                        {item?.payment?.booking?.schedule?.course?.name}
                      </span>
                    </div>
                  </td>
                  <td className="sm:px-2 px-1 py-3 text-dark">
                    {/* {item?.bType} */}
                     <div>
                       {item?.entries[0]?.amount} /SAR
                    </div>
                  </td>
                  <td className="sm:px-2 px-1 py-3 text-dark">
                    {/* {item?.bType} */}
                    <div>
                      {moment(item?.entries[0].createdAt).format('DD MMM YYYY')}
                    </div>
                  </td>
                </tr>
              </>
              ))}
            </tbody>
          </table>
          <div className="flex items-center justify-between py-3">
            <div>{/* <Pagination pageCount={1} /> */}</div>
            <div className="text-xs text-grayText md:block hidden">
              {/* Showing 0 items out of 0 results found */}
            </div>
          </div>
        </div>
      </div>


      {/*  */}
      <div>
        <div className='p-5'>
          <span>Total revenue posting amount</span>
          <div className='mt-3 w-1/3'>
            <input
              disabled
              className="border rounded p-3 rounded w-full text-sm bg-gray-300"
              name="name"
              placeholder={total}
              type="text"
            />
          </div>

          <div className='mt-5'>
            <span className='mt-5'>Bank name </span>
            <div className='mt-3 w-1/3'>
              <input
                className="border rounded p-3 rounded w-full text-sm "
                name="bankName"
                placeholder="bank name"
                type="text"
                onChange={(e) => handleChangeWithName(e.target.value, 'bankName')}
              />
            </div>
          </div>

          <div className='mt-5'>
            <span  >Note</span>
            <textarea name="note" placeholder='text ...' className="address_details_textarea" onChange={(e) => handleChangeWithName(e.target.value, 'note')} />
          </div>
        </div>
      </div>


      <div className="mt-4 mb-8 flex justify-between p-5">
        <div className="w-1/2">
          <button
            onClick={() => {
              console.log('___')
              navigate("/admin/daily-closing");
            }}
            className={
              "cursor-pointer w-3/5 md:w-1/3 h-12 rounded-md border-2 border-slate-300 bg-white py-2 px-4 font-semibold text-[#333333] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
            }
          >
            Cancel
          </button>
        </div>
        <div>
          <button
            onClick={() => {

                // handleAddBranch()

              // setShowErrors(true)
              // navigate("/admin/daily-closing/add-closing/upload");
              submit()

            }}
            className="cursor-pointer h-12 rounded-md bg-[#046F6D] py-2 px-12 font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
          >
            Submit / Print
          </button>
        </div>
      </div>

    </div>

  )
}

export default AddClosing
