import React, { useEffect, useState } from 'react';

import RighteSide from '../../components/Dashboard/RightSide/RighteSide';
import LeftSide from '../../components/Dashboard/LeftSide/LeftSide';
// import Sidebar from "../../components/global/sidebar";

function Dashboard() {
  //flex flex-warp items-center justify-between

  //flex w-full

  //mt-8
  return (
    <div>
      
      <div className="flex flex-wrap w-full m-8">
      {/* <Sidebar /> */}
        <div className="w-2/3 mr-4">
          <LeftSide />
        </div>
        <div className="w-1/4">
          <RighteSide />
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
