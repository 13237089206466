import React, { useState, useEffect } from 'react';
import headerImage from '../../pages/Register/register_header.jpg';
import logo from '../../components/almuhaidab_logo.svg';
import logo2 from '../../components/almuhaidab_title.svg';
import { useNavigate } from 'react-router-dom';
function RegisterHeader({ data, dir, t }) {
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    setTitle(data);
  }, []);

  return (
    <div className="login-background z-0">
      <div className="h-full w-full">
        <div className="flex div-main-register">
          <div className="mt-6 w-full md:w-1/2">
            <img className="inline" src={logo} alt="Logo here"></img>
            <img className="inline" src={logo2} alt="Logo here"></img>
          </div>
          <div className="md:w-1/2 flex justify-end mt-8">
            <button
              onClick={() => {
                // navigate('/login');
                window.location.replace('https://arkansite.mishari.dev/auth/login')
              }}
              className="bg-[#046F6D] mr-4 text-white w-36 h-10 rounded-sm"
            >
              {t('Login')}
            </button>
            <select
              onChange={(e) => dir(e.target.value)}
              className="h-10 mr-4 text-bold text-xl"
              style={{ border: '0', background: 'none' }}
            >
              <option value="ltr">ENG</option>
              <option value="rtl">AR</option>
            </select>
          </div>
        </div>
        <div className="c-text-header">
          <p className="text-white text-5xl text-center font-bold border-b-[#83BB45] border-b-8">{t(title)}</p>
        </div>
      </div>
    </div>
  );
}

export default RegisterHeader;
