import { useNavigate } from 'react-router-dom';
import Arrow from '../../assets/svgs/arrow.svg';
import { useLocation } from 'react-router-dom'
// import React, { useState } from 'react';
export default function StepperControl({
  submit,
  handleClick,
  currentStep,
  steps,
  otp,
  handleOTP,
  t,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  // const [buttonDisable, setButtonDisable] = useState(false);
  return (
    <>
      <hr className="my-8 h-px bg-gray-200 border-0"></hr>
      <div className="mt-4 mb-8 flex justify-between">
        <div className="w-1/2">
          <button
            onClick={() => {
              // console.log('location.pathname: ',location.pathname);
              if(location.pathname === '/admin/students/add-new'){
                navigate('/admin/students');
              } else if(location.pathname === '/admin/corporate/add-new'){
                navigate('/admin/corporate');
              } else if(location.pathname === '/admin/training-center/add-new'){
                navigate('/admin/training-center');
              }
              else if(location.pathname === '/admin/medical-center/add-new'){
                navigate('/admin/medical-center');
              }
              else {
                navigate('/login');
              }
            }}
            type={'button'}
            className={
              'cursor-pointer w-3/5 md:w-1/3 h-12 rounded-md border-2 border-slate-300 bg-white py-2 px-4 font-semibold uppercase text-[#333333] transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white'
            }
          >
            {t('CANCEL')}
          </button>
        </div>
        <div className="flex flex-row items-center justify-center">
          {currentStep === 0 ? (
            <></>
          ) : (
            <button
              type={'button'}
              onClick={() => handleClick()}
              className={`flex flex-row items-center cursor-pointer h-12 border-none outline-none focus:outline-none bg-white py-2 px-4 font-bold uppercase text-[#333333] transition duration-200 ease-in-out ${
                currentStep === 0 ? ' cursor-not-allowed opacity-50 ' : ''
              }`}
            >
              {' '}
              <img src={Arrow} className="h-[16px] w-[16px] mr-[9px]" />{' '}
              {t('BACK')}
            </button>
          )}
          {currentStep === steps.length - 1 && !otp ? (
            <button
              type={'button'}
              onClick={() => {
                console.log('clicking otp')
                handleOTP()
              }}
              className="cursor-pointer h-12 mobile-next rounded-md bg-[#046F6D] py-2 px-4 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
            >
              {t('Next')}
            </button>
          ) : currentStep === steps.length - 1 ? (
            <>
              <button
                onClick={() => {
                  console.log('clicking submit')
                  submit()}}
                className="cursor-pointer h-12 rounded-md bg-[#046F6D] py-2 px-4 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
              >
                {t('Confirm')}
              </button>
            </>
          ) : (
            <>
              <button
                onClick={() => {
                  // setButtonDisable(true)
                  console.log('clicking  . . .')
                  handleClick('next')
                }
                  
                }
                className='mobile-next cursor-pointer h-12  rounded-md bg-[#046F6D] py-2 px-4 font-semibold uppercase text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white'
              >
                {t('Next')}
              </button>
            </>
          )}
        </div>
      </div>
      <hr className="my-8 h-px bg-gray-200 border-0"></hr>
      <div className="mb-12"></div>
    </>
  );
}
