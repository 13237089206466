import React from 'react';
import fullBanner from '../../../assets/img/FullBanner.svg';

import courseImage from '../../../assets/img/Course.png';
import personImage2 from '../../../assets/img/personImage2.svg';
import personImage3 from '../../../assets/img/personimage3.svg';
import Start from '../../../assets/img/Star.svg';
import user from '../../../assets/img/user.svg';
import document from '../../../assets/img/document.svg';
import clock from '../../../assets/img/clock.svg';
import chart from '../../../assets/img/chart.png';

function LeftSide() {
  return (
    <div>
      <div>
        <img src={fullBanner} />
      </div>

      {/* Chat */}
      <div className="mt-2">
        <img src={chart} />
      </div>

      <div>
        <h1 className="font-bold text-xl mt-5">Monthly Mentors</h1>
        <div className="grid grid-cols-2 gap-3">
          <div>
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-5">
              <div className="p-5">
                {/* 1st ROW */}

                <div className="grid grid-cols-2 gap-5">
                  <div className="flex">
                    <div className="mr-2">
                      <img src={personImage2} />
                    </div>
                    <div>
                      <p className="font-bold  text-sm mt-1">Lincoln George</p>
                      <p className="text-gray-900  text-xs mt-1">
                        UI UX . Design
                      </p>
                    </div>
                  </div>
                  <div className="flex right-0">
                    <div>
                      <a href="" className="text-blue-500">
                        + Follow{' '}
                      </a>
                    </div>
                  </div>
                </div>

                {/* 2nd Row */}
                <div className="grid grid-cols-2 gap-5 mt-2 mb-5">
                  <div className="flex">
                    <div>
                      {' '}
                      <img src={document} className=" mt-0.5 mr-1" />{' '}
                    </div>
                    <div>
                      {' '}
                      <p className="text-sm">100 Coruse </p>{' '}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-2 mt-1">
                      <img src={Start} />
                    </div>
                    <div>
                      <p className="text-gray-600  text-sm mt-1">
                        4,5 (1.200 Review)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-5">
              <div className="p-5">
                {/* 1st ROW */}

                <div className="grid grid-cols-2 gap-5">
                  <div className="flex">
                    <div className="mr-2">
                      <img src={personImage3} />
                    </div>
                    <div>
                      <p className="font-bold  text-sm mt-1">Gustavo Torff</p>
                      <p className="text-gray-900  text-xs mt-1">
                        UI UX . Design
                      </p>
                    </div>
                  </div>
                  <div className="flex right-0">
                    <div>
                      <a href="" className="text-gray-500">
                        Followed{' '}
                      </a>
                    </div>
                  </div>
                </div>

                {/* 2nd Row */}
                <div className="grid grid-cols-2 gap-5 mt-2 mb-5">
                  <div className="flex">
                    <div>
                      {' '}
                      <img src={document} className=" mt-0.5 mr-1" />{' '}
                    </div>
                    <div>
                      {' '}
                      <p className="text-sm">100 Coruse </p>{' '}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-2 mt-1">
                      <img src={Start} />
                    </div>
                    <div>
                      <p className="text-gray-600  text-sm mt-1">
                        4,5 (1.200 Review)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSide;
