import React, { useState, useEffect } from 'react';
import Content from './Content';
import StepperController from './StepperControl';
import RegisterHeader from './RegisterHeader';
import Stepper from './Stepper';
import { useLocation } from 'react-router-dom';
import Footer from '../Footer/Footer';
import OTP from './OTP.js';
import { useTranslation } from 'react-i18next';
import Done from './pages/Done.js';
import { useFormContext } from 'react-hook-form';
import { Collapse } from '@material-ui/core';
import CheckIcon from '../../assets/svgs/check.svg'
import axios from 'axios';
import config from '../../config';
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import corporateService from '../../services/corporateService';
import studentService from '../../services/studentService';

function Wizard(props) {
  const data = props.data;
  let tempData = data;
  const header = props.header;
  // const submit = props.submit;
  const [currentStep, setCurrentStep] = useState(0);
  const [allData, setAllData] = useState([]);
  const location = useLocation();
  const [otp, setOtp] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [dir, setDir] = useState('ltr');
  const { formState, clearErrors, setFocus, watch } = useFormContext();
  const [isSub, setIsSub] = useState(false);

  async function submit(data) {
  // case corporate
  if(isSub) return;
if(props.type === 'corporate'){
    data['nameAr'] = data.nameEn;
    data['location'] = data.map || '--';
    data['address'] = data.address_details || '--';
    setIsSub(true);
    await corporateService.createCorporate(data).then((res)=>{
      if(typeof res === 'string' || res.status === 'failed' || res.data.status === 'failed'){
        alert('Missing Fields.');
        setIsSub(false);
        return null;
      }
      setOtp(true);
    }).catch((err)=>{
      alert('Missing Fields..');
      setIsSub(false);
    })  
  }
  // case student
 else if(props.type === 'student'){
    data['location'] = data.map || '--';
    data['address'] = data.address_details || '--';
    data['idType'] = data.id_type;
    console.log('finalll ', data);
    setIsSub(true);
    await studentService.createStudent(data).then((res) => {
      if (typeof res === 'string' || res.status === 'failed' || res.data.status === 'failed') {
        alert('Missing Fields.');
        setIsSub(false);
        return null;
      }
      setOtp(true);
    }).catch((err) => {
      alert('Missing Fields..');
      setIsSub(false);
      return null;
    })
  
  }
  // case training
  else if(props.type === 'trainning'){
    // alert('sdsd')
      data['location'] = data.map || '--';
      data['address'] = data.address_details || '--';
      setIsSub(true);
      let da = await axios({
        method: 'post',
        url: config.API_URL + '/training-center',
        headers: {
          'content-type': 'application/json'
        },
        data,
      }).then((res)=>{
        // console.log('sss',res.data)
        if(typeof res === 'string' || res.status === 'failed' || res.data.status === 'failed'){
          alert('Missing Fields.');
          setIsSub(false);
          return;
        }
        // alert('yeeey');
        setOtp(true);
      }).catch((err)=>{
        // console.log('sss',err)
        alert('Missing Fields..');
        setIsSub(false);
      })
    }
  // case medical 
  else if(props.type === 'medical'){
    data['location'] = data.map || '--';
    data['address'] = data.address_details || '--';
    setIsSub(true);
    let da = await axios({
      method: 'post',
      url: config.API_URL + '/medical-center',
      headers: {
        'content-type': 'application/json'
      },
      data,
    }).then((res)=>{
      // console.log('sss',res.data)
      if(typeof res === 'string' || res.status === 'failed' || res.data.status === 'failed'){
        alert('Missing Fields.');
        setIsSub(false);
        return;
      }
      // alert('yeeey');
      setOtp(true);
    }).catch((err)=>{
      // console.log('sss',err)
      alert('Missing Fields..');
      setIsSub(false);
    })
  } 
}
  async function handleOTP() {
    ////console.log('watch() ?>? ',watch())
    // setOtp(true);
    let sub = await submit(watch()).then((res) => {
      //console.log('subres ',res)
      // //console.log()
      // setOtp(true);
    }).catch((err) => {
      alert('Missing Fields..');
    });
    // let sub = await submit(watch());
    // console.log('sub() ?>? ',sub)
    // if(sub){
    //   setOtp(true);
    // } else {
    //   alert('Missing Fields..');
    // }
    // //console.log('sub() ?>? ',sub)
    // if(sub){
    //   setOtp(true);
    // }
    // setShowModal(true);
  }
  useEffect(() => {
    // //console.log('props.type>>>>>> ',props.type)
    if (props.type === "corporate") {
      setFocus("nameEn")
    }
    if (props.type === "student") {
      // //console.log('props.type>?????????????????',props.type)
      setFocus("nameAr")
    }
    if (props.type === "medical" || props.type === "trainning") {
      // //console.log('props.type>?????????????????',props.type)
      setFocus("name")
    }
  }, [setFocus, props.type])

  useEffect(() => {
    // //console.log('ZZZZ props.type: ',props.type)
    if (props.type === "corporate" || props.type === "student") {
      // //console.log('ZZZZ currentStep: ',currentStep)
      if (currentStep === 0) {
        // //console.log('ZZZZ formState.errors.guarantorName: ',formState.errors.guarantorName)
        if (formState.errors.guarantorName || formState.errors.guarantorPhone || formState.errors.nameAr) {
          // //console.log('ZZZZ clearErrors: ')
          clearErrors(["guarantorName", "guarantorPhone"])
        }
      }
    }

  }, [currentStep, props.type, clearErrors, formState.errors.guarantorName]);



  useEffect(() => {
    // //console.log(watch().id_type)
    if (watch().id_type == "ID") {
      // //console.log('in -----')

      // idSteps = nonIdStepsWithout.splice(2, 1)

      setStep(nonIdStepsWithout);
    }
    else {
      setStep(nonIdSteps);
    }
  }, [watch().id_type])

  //console.log('data bef? ? ? ',data.length)
  let nonIdStepsWithout = data.filter((d) => d.step.name !== "Guarantor Details");
  //console.log('data afterx? ? ? ',data.length)

  nonIdStepsWithout = nonIdStepsWithout.map((d) => d.step.name);
  let nonIdSteps = data.map((d) => d.step.name);
  let idSteps;
  if (watch().id_type == "ID") {
    idSteps = nonIdStepsWithout;
    // tempData = tempData.splice(3,1);
    //console.log('data afterx? ? ? ',data)
  }
  else {
    idSteps = nonIdSteps;
  }
  // console.log('____' , idSteps)
  const [step, setStep] = useState(idSteps)
  // //console.log('Steps , ' , step.length)
  // const step = data.map((d) => d.step.name);
  let stepNow = step.indexOf(tempData[currentStep].step.name);

  //console.log('ta[stepNow] ', stepNow);
  if (watch().id_type == "ID" && stepNow == -1) {
    stepNow = 3;
  } else if (watch().id_type == "ID" && stepNow == 2) {
    stepNow = 4;
  }
  const content = tempData[stepNow]?.Component;
  // function changingStep() {
  //   setCurrentStep(3)
  // }

  // console.log('stepNow? ? ? ',stepNow, currentStep)
  const handleClick = (direction) => {
    if (!!Object.values(formState.errors).length && direction === "next") {
      console.log('handleClick if ',Object.values(formState.errors))
      return;
    } else {
      // //console.log('handleClick else')
      let newStep = currentStep;

      direction === 'next' ? newStep++ : newStep--;
      // check if steps are within bounds
      newStep >= 0 && newStep < step.length && setCurrentStep(newStep);
    }

  };


  // //console.log(window.location.pathname)

  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (dir === 'rtl') {
      i18n.changeLanguage('ar');
    } else {
      i18n.changeLanguage('en');
    }
  }, [dir]);
  const [collapse, setcollapse] = React.useState(false);


  // useEffect(() => {
  //   if (stepNow == 2 || stepNow == -1){
  //     setCurrentStep(3)
  //   }
  // },[stepNow])

  return (
    <>
      <div dir={dir}>
        
        {location.pathname == "/student-register" || location.pathname == '/corporate-register'  || location.pathname == '/training-register'  || location.pathname ==  '/medical-register' ?
          <RegisterHeader data={header} dir={setDir} t={t} />
          :
          <></>
        }
        {otp ? (
          <div className="md:mx-36 sm:mx-10 mx-3">
            <Done type={props.type} />
          </div>
        ) : (
          <>
          {location.pathname == "/student-register" || location.pathname == '/corporate-register'  || location.pathname == '/training-register' || location.pathname == '/medical-register' ?
          <div>

            <div className='flex justify-center'>

              <div className="div-main-stepper">
                <Stepper setCurrentStep={setCurrentStep} steps={step} t={t} currentStep={currentStep} />
              </div>
            </div>
            <div className='mobile-see-more'>
              <Collapse in={collapse} >
                {
                  step.map((x, index) => (
                    <div onClick={() => {
                      if (index < currentStep) {
                        setCurrentStep(index)
                      } else if (index > currentStep) {
                        handleClick()
                      }
                    }} className='flex flex-row items-center cursor-pointer  py-3' key={index}>
                      <span className={`w-[28px] h-[28px] rounded-[80px] flex items-center justify-center text-lg mx-1 font-medium ${index === currentStep || index < currentStep ? "bg-[#046F6D] text-white" : "bg-white border border-gray-300 text-gray-300"
                        }`}>{index < currentStep ? <img className='w-[16px] h-[16px]' src={CheckIcon} /> : index + 1}</span>
                      <span className={`${index === currentStep || index < currentStep ? "text-[#046F6D]" : "text-gray-300"} font-bold`}>  {x}</span>
                    </div>
                  ))
                }
              </Collapse>
              <button className='w-full flex justify-start px-2 text-[#046F6D] text-xl outline-none border-none focus:outline-none font-bold' onClick={() => setcollapse(!collapse)}>See more</button>
            </div>
            <div className=' md:mx-36 sm:mx-10 mx-3'>

              <Content data={content} t={t} allData={allData} setAllData={setAllData} />
              {currentStep !== step.length && (
                <StepperController
                  t={t}
                  handleClick={handleClick}
                  currentStep={currentStep}
                  steps={step}
                  otp={otp}
                  handleOTP={handleOTP}
                  // submit={() => submit(allData)}
                />
              )}
            </div>
          </div>
          :
          <div className='mt-32 bg-white'>

          <div className='flex justify-center'>

            <div className="div-main-stepper ">
              <Stepper setCurrentStep={setCurrentStep} steps={step} t={t} currentStep={currentStep} />
            </div>
          </div>
          <div className='mobile-see-more'>
            <Collapse in={collapse} >
              {
                step.map((x, index) => (
                  <div onClick={() => {
                    if (index < currentStep) {
                      setCurrentStep(index)
                    } else if (index > currentStep) {
                      handleClick()
                    }
                  }} className='flex flex-row items-center cursor-pointer  py-3' key={index}>
                    <span className={`w-[28px] h-[28px] rounded-[80px] flex items-center justify-center text-lg mx-1 font-medium ${index === currentStep || index < currentStep ? "bg-[#046F6D] text-white" : "bg-white border border-gray-300 text-gray-300"
                      }`}>{index < currentStep ? <img className='w-[16px] h-[16px]' src={CheckIcon} /> : index + 1}</span>
                    <span className={`${index === currentStep || index < currentStep ? "text-[#046F6D]" : "text-gray-300"} font-bold`}>  {x}</span>
                  </div>
                ))
              }
            </Collapse>
            <button className='w-full flex justify-start px-2 text-[#046F6D] text-xl outline-none border-none focus:outline-none font-bold' onClick={() => setcollapse(!collapse)}>See more</button>
          </div>
          <div className=' md:mx-36 sm:mx-10 mx-3'>

            <Content data={content} t={t} allData={allData} setAllData={setAllData} />
            {currentStep !== step.length && (
              <StepperController
                t={t}
                handleClick={handleClick}
                currentStep={currentStep}
                steps={step}
                otp={otp}
                handleOTP={handleOTP}
                // submit={() => submit(allData)}
              />
            )}
          </div>
        </div>
          }
          </>
        )}
        {showModal ? <OTP setOtp={setOtp} setShowModal={setShowModal} /> : null}
        <>
          {location.pathname == "/student-register" || location.pathname == '/corporate-register' ?
            <Footer t={t} />
            :
            <></>
          }
        </>
      </div>
    </>
  );
}

export default Wizard;
