import AddSvg from '../../assets/svgs/Add';
import ExamSvg from '../../assets/svgs/Exam';
import MoreSvg from '../../assets/svgs/More';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from './pagination';
// import { data } from './tableData';
import axios from 'axios';
import config from '../../config';
import moment from 'moment';
import {
  Badge,
  Button,
} from '@windmill/react-ui';
import { useNavigate } from 'react-router-dom'

const sortOptions = [
  'A-Z',
  'Z-A',
];
const statusOptions = ['paid', 'pending', 'unpaid', 'failed', 'refund'];

const RetakeExamTable = () => {
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [showStatusOptions, setShowStatusOptions] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState('A-Z');
  const [selectedStatusOption, setSelectedStatusOption] = useState('ALL');
  const [query, setQuery] = useState('');
  const [data, setTableBody] = useState([]);

  const [showItem, setShowItem] = useState(0);
  const navigate = useNavigate();

  
  async function getAllList() {
    let data;
    const filters = {
      'asc': {"sort": "asc"},
      'dec': {"sort": "dec"},
      'pending': {"status": "Pending"},
      'rejected': {"status": 'Rejected'},
      'approved': {"status": 'Approved'},
      'All':{},
      '':{}
    }
    // console.log(cityFilter)
    data = await axios.post(config.API_URL + '/booking/retake-exams-list', filters[query]);
    setTableBody(data.data);
  }
  

  useEffect(() => {
    getAllList();
  }, [query]);

  async function handleAction(data, action) {
    await axios.patch(config.API_URL + '/booking/update-status/'+ data.id, {"status": action})
    getAllList();
  }
  
  return (
    <div className="my-4 md:mx-6 mx-2 rounded-md bg-pure pt-6 pb-4 md:px-4 px-2">
      <div className="flex items-center gap-2">
        <div>
          <ExamSvg />
        </div>
        <h3 className="text-xl font-semibold">Retake Exam</h3>
      </div>
      <div className="my-8 flex sm:flex-row flex-col sm:items-center justify-between gap-4">
        <div className="flex items-center gap-6">
          <div className="text-sm flex items-center gap-1">
          {/* <div className="mx-2">
              <label className="text-gray-700 text-sm">SORT:</label>
              <select
                className="mx-1"
                onChange={() => {
                  // setQuery(e.target.value)
                  // getAllList()
                }}
              >
                <option value="asc">A-Z</option>
                <option value="dec">Z-A</option>
              </select>
            </div> */}
          </div>
          {/* <div className="text-sm flex items-center gap-1">
            <p className="text-grayText">STATUS:</p>
            <span>{selectedStatusOption}</span>
            <div className="text-2xl cursor-pointer relative">
              <i
                className="uil uil-angle-down"
                onClick={() => {
                  setShowStatusOptions((prev) => !prev);
                }}
              ></i>
              {showStatusOptions && (
                <div className="absolute top-full left-1/2 -translate-x-1/2 text-sm bg-pure border border-gray-300 rounded-md">
                  {statusOptions.map((item, i) => (
                    <div
                      className={` ${
                        statusOptions.length - 1 !== i ? 'border-b' : ''
                      } border-b-gray-400 px-4
                                            ${
                                              item === 'paid'
                                                ? 'bg-green-100 text-green-500'
                                                : item === 'pending'
                                                ? 'bg-orange-100 text-orange-500'
                                                : item === 'failed'
                                                ? 'bg-red-100 text-red-500'
                                                : item === 'unpaid'
                                                ? 'bg-purple-100 text-purple-500'
                                                : item === 'refund'
                                                ? 'bg-yellow-100 text-yellow-500'
                                                : ''
                                            }
                                            hover:bg-primary hover:text-pure duration-200 cursor-pointer py-1 capitalize ${
                                              i === 0
                                                ? 'rounded-tl-md rounded-tr-md'
                                                : ''
                                            }
                                            ${
                                              i === statusOptions.length - 1
                                                ? 'rounded-bl-md rounded-br-md'
                                                : ''
                                            }
                                            `}
                      onClick={() => {
                        setSelectedStatusOption(item.toUpperCase());
                        setShowStatusOptions(false);
                      }}
                    >
                      {item}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div> */}
        </div>
        <Link
          to="/admin/retake-exam/book-retake"
          className="bg-primary py-2 px-6 flex items-center gap-2 cursor-pointer duration-300 text-sm text-pure rounded-md w-fit"
        >
          <div className="white-svg">
            <AddSvg />
          </div>
          <p>Retake Exam </p>
        </Link>
      </div>

      {/* TABLE */}
      <div className="overflow-x-auto mt-10">
        <table className="w-full text-sm text-left overflow-x-auto whitespace-nowrap">
          <thead className="md:text-sm text-[13px] bg-gray-100 text-dark">
            <tr>
              <th scope="col" className="sm:px-2 px-1 py-4">
                ID
              </th>
              <th scope="col" className="sm:px-2 px-1 py-4">
                Student Name
              </th>
              <th scope="col" className="sm:px-2 px-1 py-4 lg:flex  hidden">
                Booking Type
              </th>
              <th
                scope="col"
                className="sm:px-2 px-1 py-4 md:table-cell hidden"
              >
                Exam Date
              </th>
              {/* <th
                scope="col"
                className="sm:px-2 px-1 py-4 md:table-cell hidden"
              >
                Amount
              </th> */}
              <th
                scope="col"
                className="sm:px-2 px-1 py-4 md:table-cell hidden"
              >
                Payment Type
              </th>
              <th scope="col" className="sm:px-2 px-1 py-4">
                Status
              </th>
              <th>

              </th>

            </tr>
          </thead>
          <tbody>
            {/* {data?.map((item, i) => ( */}
            {data?.map((item, i) => (
              <>
                <tr className="bg-white border-b border-t  bg-pure md:text-sm text-[13px]">
                  <td scope="row" className="sm:px-2 px-1 py-3 ">
                    <div className="text-grayText flex items-center gap-2">
                      <div
                        className="bg-gray-100 rounded-full w-[18px] h-[18px] flex items-center justify-center md:hidden  cursor-pointer text-[16px]"
                        onClick={() => {
                          setShowItem((prev) => (prev === i + 1 ? 0 : i + 1));
                        }}
                      >
                        <i className="uil uil-angle-down"></i>
                      </div>
                      <p>{item?.id}</p>
                    </div>
                  </td>
                  <td className="sm:px-2 px-1 py-3 text-dark">
                    <div className="flex flex-col">
                      <p>{item?.nameEn}</p>
                      <span className="text-xs text-grayText">
                        {item?.email}
                      </span>
                    </div>
                  </td>
                  <td className="sm:px-2 px-1 py-3 lg:flex hidden text-dark">
                  {item?.type}
                  </td>
                  <td className="sm:px-2 px-1 py-3 text-dark md:table-cell hidden">
                    {moment(item?.date).format('YYYY-MM-DD')}
                  </td>
                  {/* <td className="sm:px-2 px-1 py-3 text-dark md:table-cell hidden">
                    {item?.amount}
                  </td> */}
                  <td className="sm:px-2 px-1 py-3 text-dark md:table-cell hidden">
                    {item?.paymentFlag}
                  </td>
                  <td className="sm:px-2 px-1 py-3 text-dark">
                    {/* <div className="flex items-center justify-between"> */}
                      {/* <div
                        className={`${
                          item?.status == 'Paid'
                            ? 'bg-green-100 text-green-500'
                            : item?.status === 'Pending'
                            ? 'bg-pink-100 text-pink-500'
                            : item?.status === 'Failed'
                            ? 'bg-red-100 text-red-500'
                            : item?.status === 'Refund'
                            ? 'bg-yellow-100 text-yellow-500'
                            : item?.status === 'Unpaid'
                            ? 'bg-purple-100 text-purple-500'
                            : ''
                        }
                                                    py-1 px-2 rounded-md w-fit`}
                      >
                        {item?.status}
                      </div> */}
                        {item.status !== 'Pending' ?
                        <Badge type={item.status === "Rejected" ? `danger` : 'success'}>{item.status}</Badge>
                        :
                        <>
                        <Button
                          style={{ backgroundColor: '#046F6D' }}
                          className="text-white mr-3"
                          onClick={() => {
                            handleAction(item, 'Accepted')
                            getAllList()
                          }}
                        >
                          Accept
                        </Button>
                        <Button
                          style={{ backgroundColor: '#D80027' }}
                          className="bg-red-600 text-white"
                          onClick={() => {
                            handleAction(item, 'Rejected')
                            getAllList()
                          }}
                        >
                          Reject
                        </Button>
                      </>
                      }
                     
                    {/* </div> */}
                  </td>
                  <td>
                  <div className=""  onClick={() =>{
                           navigate('/admin/booking-management/book-details/'+item.id)
                        }}>
                        <MoreSvg />
                      </div>
                  </td>
                </tr>
                <tr
                  className={`${
                    showItem === i + 1 ? 'w-full' : 'hidden'
                  } text-[13px]`}
                >
                  <td colSpan={6} className="table-cell ">
                    <ul className="flex justify-between flex-col md:text-sm text-[13px]">
                      <li className="bg-gray-100 py-4 flex items-center justify-between   ">
                        <div className="flex-1 pl-4 font-semibold flex items-center gap-1">
                          <div className="w-[7px] h-[7px] rounded-full bg-green-500"></div>
                          <p>Exam Date</p>
                        </div>
                        <p className="flex-1 px-4">{moment(item?.date).format('YYYY-MM-DD')}</p>
                      </li>
                      <li className=" py-4 flex items-center justify-between   ">
                        <div className="flex-1 pl-4 font-semibold flex items-center gap-1">
                          <div className="w-[7px] h-[7px] rounded-full bg-green-500"></div>
                          <p>Booking Type</p>
                        </div>
                        <p className="flex-1 px-4">{item?.type}</p>
                      </li>
                      <li className="bg-gray-100 py-4 flex items-center justify-between   ">
                        <div className="flex-1 pl-4 font-semibold flex items-center gap-1">
                          <div className="w-[7px] h-[7px] rounded-full bg-green-500"></div>
                          <p>Payment Type</p>
                        </div>
                        <p className="flex-1 px-4">{item?.paymentFlag}</p>
                      </li>
                    </ul>
                  </td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
        <div className="flex items-center justify-between py-3">
          <div>
            <Pagination pageCount={1} />
          </div>
          <div className="text-xs text-grayText md:block hidden">
            Showing 0 items out of 0 results found
          </div>
        </div>
      </div>
    </div>
  );
};

export default RetakeExamTable;
