import React , {useState, useEffect} from 'react'
import { useLocation } from 'react-router-dom'
import axios from 'axios';
import config from '../../config';

const InquiryForm = ({setErrorMessage,setAvailableExams,setPastExams, setInquiry, setStudentId, setSelectedBook, isPayment=false, selectedBook}) => {
    const location = useLocation();
    const [allData, setAllData] = useState([]);
    const [inquiryError, setInquiryError] = useState('');
    const handleChangeWithName = (value, name) => {
      console.log(value, name)
      setAllData({...allData, [name]: value})
      console.log(allData);
    }

    async function submit() {
      try {
        let data;
        data = await axios.post(config.API_URL + '/booking/retake-exam/inquiry',allData).then((res) => {
          console.log('resL ',res.data)
          if(!res.data.studentId){
            setInquiryError('No student found with this ID/Code.');
            setAvailableExams([]);
            setPastExams([]);
            setInquiry(false);
            setSelectedBook();
            return
          }
          setStudentId(res.data.studentId)
          setInquiryError('');
          if(res.data && res.data.pastBookings){
            setPastExams(res.data.pastBookings);
            setAvailableExams(res.data.availableBookings);
            setInquiry(true)

          }
        }).catch((err) =>{
          // console.log('');
          setErrorMessage('No available exams found with the selected filters.')
          setAvailableExams([]);
          setPastExams([]);
          setInquiry(true)
          setStudentId('');
          setSelectedBook();
        })
        // console.log(data.data);

      } catch (error) {
        setErrorMessage('No available exams found with the selected filters..')
        setAvailableExams([]);
        setPastExams([]);
        setInquiry(true);
        setStudentId('');
        setSelectedBook();
      }
    }

    useEffect(() => {

    },[])


    return (
      <div>
        <div className='my-8 flex lg:flex-row flex-col gap-4 w-full'>
            <div className='flex lg:flex-[0.3] gap-4 sm:flex-row flex-col'>
                <div className='flex flex-col gap-1.5 flex-1 '>
                    <label className='text-dark font-medium text-sm'>Student ID/Code</label>
                    {isPayment && selectedBook ?
                     <input
                     value={selectedBook?.studentId}
                     disabled
                     type='text'
                     placeholder={selectedBook?.studentId}
                     className='outline-none border border-gray-200 rounded-md py-2 px-3 text-sm text-gray-500 w-full'
                     />
                  :
                  <input
                  onChange={(e) => {
                    console.log('ppp ', e.target.value)
                    handleChangeWithName(parseInt(e.target.value), 'studentId')
                  }}
                  type='text'
                  placeholder='Student ID / Code'
                  className='outline-none border border-gray-200 rounded-md py-2 px-3 text-sm text-gray-500 w-full'
                  />

                  }
                </div>
                {/* <div className='flex flex-col gap-1.5 flex-1'>
                    <label className='text-dark font-medium text-sm'>Booking Type</label>
                    <select
                      name="bookingType"
                      onChange={(e) => {
                        handleChangeWithName(e.target.value, 'bookingType')
                      }}
                      className="select-dropdown-wizard text-gray-500 border"
                    >
                      <option value="" disabled selected key={'bookingType_'+1}>
                        {' '}
                        Select Booking Type
                      </option>
                      <option value="Student" key={'bookingType_'+2}>
                        Student
                      </option>
                      <option value="Corporate" key={'bookingType_'+2}>
                        Corporate
                      </option>

                    </select>
                </div> */}
            </div>
            {
                location.pathname.split('/').includes('payment' || 'succeed')
                ?
                    ""
                :
                <div className='flex lg:justify-end lg:items-end'>
                    <button className='bg-primary h-fit w-fit text-pure py-2 md:px-12 sm:px-8 px-4 font-semibold rounded-md text-sm hover:bg-primaryHover'
                    onClick={()=>{
                      // console.log('43534')
                      submit()
                      // setErrorMessage('No available exams found with the selected filters..')
                    }}
                    >Enquiry</button>
                </div>
            }

        </div>
        <div>
           <h1 className='flex text-red-500 '>{inquiryError}</h1>
           </div>
        </div>
    )
}

export default InquiryForm
