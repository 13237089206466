import React from 'react';

function OTP({ setOtp, setShowModal }) {
  return (
    <>
      <div
        className="justify-center items-center flex overflow-x-hidden
      overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
      >
        <div className="w-1/3 my-6 mx-auto max-w-4xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            <h1 className="text-center text-3xl font-bold mt-12">
              OTP Verification
            </h1>
            <p className="text-center mt-4 text-[#718096]">
              Please enter one time OTP to verify your account
            </p>
            <p className="text-center mt-4 font-bold">
              A code has been sent to mobile number: ********12
            </p>
            <div
              id="otp"
              class="flex flex-row justify-center text-center px-2 mt-5"
            >
              <input
                class="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="first"
                maxlength="1"
              />
              <input
                class="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="second"
                maxlength="1"
              />
              <input
                class="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="third"
                maxlength="1"
              />
              <input
                class="m-2 border h-10 w-10 text-center form-control rounded"
                type="text"
                id="fourth"
                maxlength="1"
              />
            </div>
            <p className="text-center mt-4 font-bold">
              Don't Get The Code {'  '}
              <span className="underline text-[#046F6D] cursor-pointer decoration-[#046F6D]">
                Resend It
              </span>
            </p>
            <div className="flex items-center p-2 border-t border-solid border-slate-200 rounded-b m-12">
              <button
                className="text-white bg-[#046F6D] w-full h-12"
                type="button"
                onClick={() => {
                  setShowModal(false);
                  setOtp(true);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}

export default OTP;
