import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import branchsuccess from '../../../components/branch-success.svg';
import axios from 'axios';
import regionService from '../../../services/regionService';
import branchService from '../../../services/branchService';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import config from '../../../config';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import moment from 'moment';
import more from '../../../assets/img/more.svg'

import HijriDatePicker from '../../../components/HijriDatePicker';

function AmanaInvoice() {
  const [done, setDone] = useState(false);
  const [allData, setAllData] = useState([]);
  const [search, setSearch] = useState(false);
  const [Sucess, setSucess] = useState(false);
  const [message, setMessage] = useState('');

  const [productTypeError, setProductTypeError] = useState(true);
  const [productCodeError, setProductCodeError] = useState(true);

  const [showTableOne, setShowTableOne] = useState(false);
  const [showTableTwo, setShowTableTwo] = useState(false);

  const [showErrors, setShowErrors] = useState(false);

  const content = useRef(null);
  const resultsPerPage = 10;

  const [tableBody, setTableBody] = useState([]);
  const [batchBody, setBatchBody] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState([]);
  const [total, setTotal] = useState(0);
  
  const navigate = useNavigate();

  const [pageTable1, setPageTable1] = useState(1);
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  const handleChangeWithName = (value, name) => {
    console.log(value);
    setAllData({ ...allData, [name]: value });

    // ToDo
    // setAllData({ ...allData, ['courseId']: courseId });

    // console.log(allData);
  };

async function createBatch() {
  try {
    // console.log('selectedStudents: ',selectedStudents);
    if(selectedStudents.length === 0){
      alert('Please Select Students to create the batch');
      return;
    }
    let batchNumber = Math.floor(100000 + Math.random() * 900000);
    let batchData = {
      batch: batchNumber,
      studentBooking: selectedStudents
    }
    // console.log('batchData: ',batchData);
    // return
    let data = await axios.post(
      config.API_URL + '/finance/municipality-invoicing/batch',batchData
    ).then((res) => {

      setSelectedStudents([]);
      setTotal(0);
      getData()
    }).catch((err) =>{

    });
  } catch (error) {
    
  }
}

  async function getData() {
    try {
      let data;
      let tempData = allData;
      tempData.isBatched = false;
      data = await axios.post(
        config.API_URL + '/finance/municipality-invoicing/inquiry',tempData
      );
      // console.log(data);
      if (!data || !data.data || data.data.status === 'failed') {
        if (data.data.message) {
          setMessage(data.data.message);
          return;
        } else {
          setMessage('No data found with the selected period');
          return;
        }
        setSucess(true);
        setSearch(false);
        return;
      }
      setTableBody(data.data);
    
     setShowTableOne(true);
      setSucess(true);
      setSearch(true);
    } catch (error) {
      setTableBody([]);
      console.log('getData error: ',error);
    }
  }


  async function getBatchData() {
    try {
      let data;
      let tempData = allData;
      tempData.isBatched = true;
      data = await axios.post(
        config.API_URL + '/finance/municipality-invoicing/inquiry',tempData
      );
      // console.log(data);
      if (!data || !data.data || data.data.status === 'failed') {
        if (data.data.message) {
          setMessage(data.data.message);
          return;
        } else {
          setMessage('No data found with the selected period');
          return;
        }
        setSucess(true);
        setSearch(false);
        return;
      }
      setBatchBody(data.data);
    
      // setShowTableOne(true);
      setSucess(true);
      setSearch(true);
    } catch (error) {
      setBatchBody([])
      console.log('getData error: ',error);
    }
  }

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">
          {!done ? (
            <div className="flex flex-col p-4">
              <h1 className="text-2xl p-4">Courses Attended Students</h1>
              <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>
              <div className="mx-2 grid grid-cols-3 gap-4">
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
                    From Date
                  </div>
                  <div className="div-dropdown-wizard">
                    <input
                      type="date"
                      onChange={(e) => {
                        handleChangeWithName(e.target.value,'from')
                        setProductTypeError(false);
                      }}
                    />
                      {/* <HijriDatePicker
                            inputName={'from'}
                            setAllData={setAllData}
                            allData={allData}
                          /> */}
                  </div>
                  {productTypeError && showErrors ? (
                    <span className="text-red-400">select date</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
                    To Date
                  </div>
                  <div className="div-dropdown-wizard">
                    <input
                      type="date"
                      onChange={(e) => {
                        handleChangeWithName(e.target.value,'to')
                        setProductCodeError(false);
                      }}
                    />
                      {/* <HijriDatePicker
                            inputName={'to'}
                            setAllData={setAllData}
                            allData={allData}
                          /> */}
                  </div>
                  {productTypeError && showErrors ? (
                    <span className="text-red-400">select date</span>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="mt-9 justify-self-start">
                  <Button
                    onClick={() => {
                      if (
                        productCodeError == false &&
                        productTypeError == false
                      ) {
                        getData()
                        setSearch(true);
                        setSucess(true);
                        // setShowTableOne(true);
                      }
                      setShowErrors(true);
                    }}
                    className="w-32 h-16"
                    style={{
                      backgroundColor: '#046F6D',
                      float: 'right',
                      marginRight: '15px',
                      marginBottom: '15px',
                    }}
                  >
                    <span className="ml-2 font-bold">Search</span>{' '}
                  </Button>
                </div>
              </div>
              <hr className="my-8 h-px bg-gray-200 border-0 dark:bg-gray-700"></hr>

              {!search ? (
                <>{Sucess ? <h1>{message}</h1> : <></>}</>
              ) : (
                <div>
                  <div className="flex justify-between">
                    {/* <div>
                      <div className="flex my-2">
                        <div className="mx-2">
                          <label className="text-gray-700 text-sm">SORT:</label>
                          <select
                            className="mx-1"
                            onChange={(e) => {
                              // setQuery(e.target.value)
                              // getAllList()
                            }}
                          >
                            <option value="asc">A-Z</option>
                            <option value="dec">Z-A</option>
                          </select>
                        </div>
                        <div>
                          <label className="text-gray-700 text-sm">
                            STATUS:
                          </label>
                          <select
                            className="mx-1"
                            onChange={(e) => {
                              // setQuery(e.target.value)
                              // getAllList()
                            }}
                          >
                            <option value="All">ALL</option>
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                            <option value="rejected">Rejected</option>
                          </select>
                        </div>
                      </div>
                    </div> */}

                    <div>
                      <TabList className="flex flex-wrap -mb-px p-2">

                            <Tab onClick={() =>{ 
                              getBatchData();
                              setShowTableOne(false);
                              }} className="inline-block p-4 border-b-2 border-transparent rounded-t-lg  active text-green-500 dark:border-green-500">
                              Old
                            </Tab>
                            <Tab onClick={() => {
                              getBatchData();
                              setShowTableOne(true);
                              }} 
                            className="inline-block p-4 border-b-2 border-transparent rounded-t-lg  active text-green-500 dark:border-green-500">
                              New
                            </Tab>
            
                      </TabList>
                    </div>
                  </div>
                  <div>
                    {showTableOne ? (
                      <div>
                         <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr className="bg-[#F7F7F7]">
                  <TableCell>ID</TableCell>
                  <TableCell>Student Full name</TableCell>
                  <TableCell>Exam title</TableCell>
                  <TableCell>Branch name</TableCell>
                  <TableCell>Attending date</TableCell>
                  <TableCell>Amana fees</TableCell>
                  {/* <TableCell></TableCell> */}
                </tr>
              </TableHeader>
              <TableBody>
                {tableBody?.map((score, i) => (
                  <TableRow key={i}>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div className='flex space-x-4'>
                        <input type="checkbox" className="cursor-pointer"
                          onChange={(e)=>{
                          let tempSession = selectedStudents;
                          // console.log('e.target.value: ',e.target.checked)
                          let tempTotal = total;
                          if(e.target.checked){
                            tempSession.push({
                              studentId: score?.student?.id,
                              bookingId: score?.bookingId
                            });
                            tempTotal += parseFloat(score?.booking?.schedule?.course?.fee[0]?.municipalityAmount);
                          } else {
                            tempSession = tempSession.filter((sc) => Number(sc.studentId) !== Number(score?.student?.id) ||  Number(sc.bookingId) !== Number(score?.bookingId) );
                            tempTotal -= parseFloat(score?.booking?.schedule?.course?.fee[0]?.municipalityAmount);
                          }
                          setSelectedStudents(tempSession);
                          setTotal(tempTotal)
                        }}
                        />
                          <p className="font-semibold">{score?.student?.id}</p>
                        </div>
                      </div>
                  
                     </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.student?.nameEn}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.booking?.schedule?.course?.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* <span className="text-sm">
                        {score?.student?.branch?.name}
                      </span> */}
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.student?.branch?.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{moment(score?.booking?.schedule?.scheduleSession[0]?.date).format('DD MMM YYYY')}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{moment(score?.booking?.schedule?.scheduleSession[0]?.date).format('DD MMM YYYY')}</span> */}
                    </TableCell>
                   
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</span> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <Pagination
                totalResults={tableBody?.length}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              />
            </TableFooter>
          </TableContainer>
          <div className="mt-4 mb-8 flex justify-between">
            <div className="w-1/2">
             <h1>Total Amana Fees:  {total} /SAR</h1>
            </div>
            <div>
              <button
                onClick={() => createBatch()}
                className="cursor-pointer h-12 rounded-md bg-[#046F6D] py-2 px-12 font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
              >
                Submit
              </button>
            </div>
          </div>
                      </div>
                    ) : (
                      <div>
                        {/* <h1>No data found with the selected period</h1> */}
                        <div>
                         <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr className="bg-[#F7F7F7]">
                  <TableCell>ID</TableCell>
                  <TableCell>Student Full name</TableCell>
                  <TableCell>Exam title</TableCell>
                  <TableCell>Branch name</TableCell>
                  <TableCell>Attending date</TableCell>
                  <TableCell>Amana fees</TableCell>
                  <TableCell>Batch Number</TableCell> 
                  <TableCell></TableCell>
                </tr>
              </TableHeader>
              <TableBody>
                {batchBody?.map((score, i) => (
                  <TableRow key={i}>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div className='flex space-x-4'>
                          <p className="font-semibold">{score?.student?.id}</p>
                        </div>
                      </div>
                  
                     </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.student?.nameEn}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.booking?.schedule?.course?.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      {/* <span className="text-sm">
                        {score?.student?.branch?.name}
                      </span> */}
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.student?.branch?.name}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{moment(score?.booking?.schedule?.scheduleSession[0]?.date).format('DD MMM YYYY')}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{moment(score?.booking?.schedule?.scheduleSession[0]?.date).format('DD MMM YYYY')}</span> */}
                    </TableCell>
                   
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</span> */}
                    </TableCell>
                    <TableCell>
                    <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{score?.batch}</p>
                        </div>
                      </div>
                      {/* <span className="text-sm">{score?.booking?.schedule?.course?.fee[0]?.municipalityAmount}</span> */}
                    </TableCell>
                    <TableCell><a onClick={()=> navigate('/admin/amana-invoice/'+score?.batch)}><img src={more} /></a></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <Pagination
                totalResults={batchBody?.length}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              />
            </TableFooter>
          </TableContainer>

                      </div>
                      </div>
                    )}
                  </div>
                </div>
              )}

              <></>
            </div>
          ) : (
            <>
              <div className="flex justify-center">
                <img src={branchsuccess}></img>
              </div>
              <div className="flex justify-center">
                <div>
                  <h1 className="text-3xl font-bold">
                    Branch Has Been Successfully Added
                  </h1>
                  <p className="my-6 flex justify-center font-bold text-[#ACA9A9]">
                    Be Ready To Assign It
                  </p>
                  <div className="flex justify-center">
                    <button
                      onClick={() => console.log('H')}
                      className="my-4 mb-12 w-64 cursor-pointer h-12 rounded-md bg-[#046F6D] font-semibold text-white transition duration-200 ease-in-out hover:bg-slate-700 hover:text-white"
                    >
                      Branches
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default AmanaInvoice;
