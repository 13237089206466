import axios from "../api/axios";
import config from "../config";
import authHeader from "../services/auth-header";

const routes = [
  {
    path: '/admin/dashboard',
    icon: 'DashboardIconn',
    name: 'Dashboard',
  },
  // {
  //   path: '/admin/request-management',
  //   icon: 'DashboardIconn',
  //   name: 'Request management',
  //   permissions: ['read_2','edit_2'],
  // },
  {
    icon: 'CoursesIconn',
    name: 'Booking Management',
    permissions: ['read_9', 'edit_9'],
    routes: [
      // {
      //   path: '/admin/booking-requests',
      //   name: 'Booking Requests',
      // },
      {
        path: '/admin/booking-management',
        name: 'Booking Management',
	permissions: ['read_9'],
      },
      {
        path: '/admin/retake-exam',
        name: 'Retake Exam',
	permissions: ['read_9']
      },
    ],
  },
  {
    path: '/admin/Scheduling',
    icon: 'Scheduling',
    name: 'Scheduling',
    permissions: ['read_8', 'edit_8'],
  },
  {
    path: '/admin/students',
    icon: 'studentIcon',
    name: 'Students',
    permissions: ['read_3', 'edit_3'],
  },
  // {
  //   path: '/admin/instructors',
  //   icon: 'Instructors',
  //   name: 'Instructors',
  // },
  // {
  //   path: '/admin/staff',
  //   icon: 'StaffIconn',
  //   name: 'Staff',
  // },
  {
    path: '/admin/user-management',
    icon: 'MyCourses',
    name: 'User Management',
    permissions: ['read_1', 'edit_1'],
  },
  {
    path: '/admin/branch-management',
    icon: 'MyCourses',
    name: 'Branch Management',
    permissions: ['read_2', 'edit_2'],
  },
  {
    path: '/admin/course-management',
    icon: 'MyCourses',
    name: 'Course Management',
    permissions: ['read_6', 'edit_6'],
  },
  {
    path: '/admin/scores',
    icon: 'examResults',
    name: 'Scores',
    permissions: ['read_19'],
  },
  {
    path: '/admin/finance',
    icon: 'PaymentIconn',
    name: 'Finance',
    // permissions: ['read_10', 'edit_10'],
    routes: [
      {
        path: '/admin/all-payments',
        name: 'All payments',
        permissions: ['read_10', 'read_17'],
      },
      {
        path: '/admin/fees-setup',
        name: 'Fees Setup',
        permissions: ['read_10'],
      },
      {
        path: '/admin/refund-corporate',
        name: 'Refund Corporate/Student Payment',
        permissions: ['read_10', 'read_17'],
      },
      // {
      //   path: '/admin/advanced-payment',
      //   name: 'Advanced Payment',
      //   permissions: ['read_10','read_17'],
      // },
      // {
      //   path: '/admin/',
      //   name: 'Advanced payment',
      //   permissions: ['read_10','read_17'],
      // },
      {
        path: '/admin/ledger-setup',
        name: 'Ledger setup',
        permissions: ['read_10'],
      },
      {
        path: '/admin/amana-invoice',
        name: 'Amana invoice',
        permissions: ['read_10', 'read_18'],
      },
      {
        path: '/admin/daily-closing',
        name: 'Daily Closing',
        permissions: ['read_10', 'read_17'],
      },
    ],
  },
  {
    path: '/admin/corporate',
    icon: 'companies',
    name: 'Corporate',
    permissions: ['read_4', 'edit_4'],
  },
  // {
  //   path: '/admin/training-center',
  //   icon: 'building',
  //   name: 'Training Center',
  // //   permissions: ['read_8','edit_8'],
  // },
  // {
  //   path: '/admin/medical-center',
  //   icon: 'medical',
  //   name: 'Medical Center',
  //   //   permissions: ['read_8','edit_8'],

  // },
  // {
  //   path: '/admin/reports',
  //   icon: 'reports',
  //   name: 'Reports',
  // },
  {
    path: '/admin/health-check',
    icon: 'reports',
    name: 'Health Check Result',
    permissions: ['read_20'],

  },
];


// let privileges = [];
// async function checkRoutesPermission() {
//   await axios.post(config.API_URL + "/auth/test-token", { token: localStorage.getItem("user"),userId: localStorage.getItem("userId") }, { headers: authHeader() }).then((result) => {
//     return 1;
//   }).catch((err) => {
//     console.log('privv error: ', err);
//     return [];
//   })
// }

async function getRoutes() {
    let privileges = [];
    let routeList = [];
    await axios.post(config.API_URL + "/auth/test-token", { token: localStorage.getItem("user"), userId: localStorage.getItem("userId") }, { headers: authHeader() }).then((result) => {
      privileges = result?.data?.privileges;
    }).catch((err) => {
      privileges = [];
    })
    for (let route of routes) {
      if (route.routes && route.routes.length > 0) {
        let temp = route;
        temp.routes_ = []
        for (let sup of route.routes) {
          if (privileges && Array.isArray(privileges)) {
            for (let pr of privileges) {
              if (!sup.permissions) continue;
              // console.log('sup:',sup);
              if (sup.permissions && (sup.permissions.includes('read_' + pr.id) && pr.read === 1) || (sup.permissions.includes('create_' + pr.id) && pr.create === 1)) {
                temp.routes_.push(sup);
                break;
              }
            }
          }
        }
        if (temp.routes_.length > 0) {
          temp.routes = temp.routes_;
          delete temp.routes_;
          routeList.push(temp);
        }
  
      } else if (route.permissions) {
        if (privileges && Array.isArray(privileges)) {
  
          for (let pr of privileges) {
            if (pr.routes) {
              let temp = pr;
              temp.routes = []
              for (let sup of pr.routes) {
                if ((sup.permissions.includes('read_' + pr.read) && pr.read === 1) || (sup.permissions.includes('create_' + pr.create) && pr.create === 1)) {
                  temp.routes.push(route);
                  break;
                }
              }
              if (temp.routes.length > 0) {
                routeList.push(temp);
              }
            } else {
              if ((route.permissions.includes('read_' + pr.id) && pr.read === 1) || (route.permissions.includes('create_' + pr.id) && pr.create === 1)) {
                routeList.push(route);
                break;
              }
            }
          }
        }
      } else {
        routeList.push(route);
      }
    }
    return routeList;
  }
export default getRoutes;

// export default routes;
