import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import ChartPercentage from '../../../assets/img/ChartPercentage.svg';

import courseImage from '../../../assets/img/Course.png';
import personImage from '../../../assets/img/personImage.svg';
import Start from '../../../assets/img/Star.svg';
import user from '../../../assets/img/user.svg';
import document from '../../../assets/img/document.svg';
import clock from '../../../assets/img/clock.svg';

function RighteSide() {
  const [value, onChange] = useState(new Date());

  return (
    <div>
      {/* CALENDER */}
      <div>
        <Calendar onChange={onChange} value={value} />
      </div>

      {/* CARD */}
      <div className="grid grid-cols-2  mt-5">
        <div>
          <div class="max-w-sm  border border-gray-200 rounded-lg shadow-md bg-[#575757] ">
            <div class="p-5">
              <div>
                <h1 className="font-bold text-white">Running Course </h1>
                <h1 className="text-5xl text-white ">50 </h1>
              </div>
              <div className="grid grid-cols-2 gap-3 mt-2">
                <div>
                  <img className="" src={ChartPercentage} />
                </div>
                <div className="grid grid-rows-2 text-white ">
                  <div className="font-bold">100</div>
                  <div>Course</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="max-w-sm  border border-gray-200 rounded-lg shadow-md bg-[#046F6D] ">
            <div class="p-5">
              <div>
                <h1 className="font-bold text-white">Running Course </h1>
                <h1 className="text-5xl text-white ">50 </h1>
              </div>
              <div className="grid grid-cols-2 gap-3 mt-2">
                <div>
                  <img className="" src={ChartPercentage} />
                </div>
                <div className="grid grid-rows-2 text-white ">
                  <div className="font-bold">100</div>
                  <div>Course</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Courses */}

      <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow-md dark:bg-gray-800 dark:border-gray-700 mt-5">
        <img className="rounded-t-lg mx-auto mt-5" src={courseImage} />

        <div className="p-5">
          {/* 1st ROW */}
          <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white mx-auto ml-4">
            Animation is the Key of Successfull UI/UX Design
          </h5>

          {/* 2nd Row */}

          <div className="grid grid-cols-2 gap-5">
            <div className="flex">
              <div className="mr-2">
                <img src={personImage} />
              </div>
              <div>
                <p className="text-gray-600  text-sm mt-1">Emerson Siphron</p>
              </div>
            </div>
            <div className="flex right-0">
              <div className="mr-2 mt-1">
                <img src={Start} />
              </div>
              <div>
                <p className="text-gray-600  text-sm mt-1">4,5</p>
              </div>
            </div>
          </div>

          {/* 3rd Row */}
          <div className="grid grid-cols-3 gap-5 mt-2 mb-5">
            <div className="flex">
              <div>
                {' '}
                <img src={user} className="mt-0.5 mr-1" />{' '}
              </div>
              <div>
                {' '}
                <p className="text-sm">500 Student </p>{' '}
              </div>
            </div>
            <div className="flex">
              <div>
                {' '}
                <img src={document} className=" mt-0.5 mr-1" />{' '}
              </div>
              <div>
                {' '}
                <p className="text-sm">5 Modul </p>{' '}
              </div>
            </div>
            <div className="flex">
              <div>
                {' '}
                <img src={clock} className=" mt-0.5 mr-1" />{' '}
              </div>
              <div>
                {' '}
                <p className="text-sm">1h 30m </p>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RighteSide;
