import React, { useState } from 'react';

import DatePicker from "react-multi-date-picker";
import InputIcon from "react-multi-date-picker/components/input_icon"

import arabic from "react-date-object/calendars/arabic";
import arabic_ar from "react-date-object/locales/arabic_ar";
import arabic_en from 'react-date-object/locales/arabic_en';

import { Controller } from 'react-hook-form';

import english from "react-date-object/calendars/gregorian";
import english_en from "react-date-object/locales/gregorian_en";

import '@deskpro/react-datepicker-hijri/dist/react-datepicker.css';
import Calendar from '../../assets/svgs/light.svg';
import moment from 'moment-hijri';
export const HijriDatePicker = ({
  control,
  name,
  label,
  placeholderText,
  minDate,
  maxDate,
  disabled,
  inputName,
  setAllData,
  allData
}) => {
  const [isHijri, setIsHijri] = useState(false);
  const [calendarTitle, setCalendarTitle] = useState('Hijri');


  const [value, setValue] = useState();
  

  if(isHijri){
    if (name === 'DateOfBirth'){
      maxDate = moment().format('iYYYY-iM-iD');
      minDate = "1300-01-01";
    } else {
      minDate = moment().format('iYYYY-iM-iD');//Date.now();
      maxDate = "1500/12/30";
    }
  } else {
    if (name === 'DateOfBirth'){
      maxDate = moment().format('YYYY-MM-DD');
      minDate = "1990-01-01";
    } else {
      minDate = moment().format('YYYY-MM-DD');//Date.now();
      maxDate = "2100-12-31";
    }
  }
console.log('name: max min ',name,maxDate,minDate)
  if (control) {
    return (
      <div className=" flex flex-col text-sm font-medium">
        {React.isValidElement(label) ? (
          label
        ) : (
          <span className="text-sm font-medium text-[#141522] mb-1">{label}</span>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => {
            // console.log('Date.now()',Date.now().to)
            return (
              <div className={`flex flex-row items-center justify-between px-[20px] h-[50px] border rounded ${error?.message ? "border-[red]" : "border-[rgb(207 207 207 / var(--tw-border-opacity))]"}`}>
                <DatePicker
                  // inputClass
                  // containerClassName=' w-full bg-black'
                  // inputClass='w-full'
                  style={{
                    width: "100%",
                    //boxSizing: "border-box",
                    height: "26px",
                    border: 'none',
                    boxShadow: "none"
                  }}
                  containerStyle={{
                    width: "100%",
                    border: 'none',
                  }}
                  value={value || new Date()}
                  onChange={(e) => {
                    // console.log(e?.toDate?.())
                    setValue(e);
                    if (setAllData && inputName && allData) {
                      setAllData({ ...allData, [inputName]: moment(e?.toDate?.().toString()).format('YYYY-MM-DD') });
                    }
                  }}
                  calendar={isHijri === true ? arabic : english}
                  locale={isHijri === true ? arabic_en : english_en}
                  minDate={minDate}
                  maxDate={maxDate}
                >

                  <h1
                    className='cursor-pointer bg-blue-300 font-bold py-2'
                    onClick={() => {
                      setCalendarTitle(isHijri === true ? 'Hijri' : 'Goregaon')
                      setIsHijri(!isHijri);
                    }}> {calendarTitle}</h1>
                </DatePicker>
              </div>
            );
          }}
        />
      </div>
    );
  } else {
    return (
      <div className=" flex flex-col text-sm font-medium">

        <div className={`flex flex-row gap-3 items-center justify-between h-[32px] `}>
          <DatePicker
          inputClass='cursor-pointer'
           style={{
            width: "100%",
            //boxSizing: "border-box",
            height: "26px",
            border: 'none',
            boxShadow: "none",
          }}
          containerStyle={{
            width: "100%",
            border: 'none',
          }}
            value={value || new Date()}
            onChange={(e) => {
              // console.log(e?.toDate?.())
              setValue(e);
              if (setAllData && inputName && allData) {
                setAllData({ ...allData, [inputName]: moment(e?.toDate?.().toString()).format('YYYY-MM-DD') });
              }
            }}
            calendar={isHijri === true ? arabic : english}
            locale={isHijri === true ? arabic_en : english_en}
            minDate={minDate}
            maxDate={maxDate}    
            disabled={disabled || false}
          >

            <h1
              className='cursor-pointer bg-blue-300 font-bold py-2'
              onClick={() => {
                setCalendarTitle(isHijri === true ? 'Hijri' : 'Goregaon')
                setIsHijri(!isHijri);
              }}> {calendarTitle}</h1>
            {/* <button
        style={{ margin: "5px" }}
        onClick={() => setValue(new Date())}
      >
        TODAY
      </button> */}
          </DatePicker>

        </div>


      </div>
    );
  }

};

export default HijriDatePicker;
