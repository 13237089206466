import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/Typography/PageTitle';
import SectionTitle from '../../components/Typography/SectionTitle';
import {
  Table,
  TableHeader,
  TableCell,
  TableBody,
  TableRow,
  TableFooter,
  TableContainer,
  Badge,
  Avatar,
  Button,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Pagination,
  Modal,
} from '@windmill/react-ui';
import { SearchIcon, TablesIcon } from '../../components/icons';
import response from './demo';
import studentIcon from '../../components/icons/studentIcon.svg';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import more from '../../assets/img/more.svg'
import MailIcon from '../../assets/img/MailIcon.svg';
import LocationIcon from '../../assets/img/LocationIcon.svg';
import CalenderToday from '../../assets/img/CalenderToday.svg';
import EditUserSVG from '../../assets/svgs/user-edit.svg';
import TransferSVG from '../../assets/svgs/transfer.svg';
import ResetPasswordSVG from '../../assets/svgs/reset-password.svg';
import corporateSVG from '../../assets/svgs/company.svg';
import UserSquare from '../../assets/svgs/user-square.svg';
import config from '../../config';
import { set } from 'lodash';

// make a copy of the data, for the second table
const response2 = response.concat([]);

function Student() {
  const [pageTable1, setPageTable1] = useState(1);
  const [pageTable2, setPageTable2] = useState(1);
  const [tableBody, setTableBody] = useState([]);

  const [studentInfo, setStudentInfo] = useState([]);

  const [checkPassword, setCheckPassword] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isTransferModalOpen, setIsTransferModalOpen] = useState(false);
  function openModal() {
    setIsModalOpen(true)
  }
  function closeModal() {
    setIsModalOpen(false)
  }

  function openAddModal() {
    setIsAddModalOpen(true)
  }
  function closeAddModal() {
    setIsAddModalOpen(false)
  }

  function openTransferModal() {
    setIsTransferModalOpen(true)
  }
  function closeTransferModal() {
    setIsTransferModalOpen(false)
  }


  const [showTableItemMenu, setShowTableItemMenu] = useState(false);
  const [tableItemIndex, setTableItemIndex] = useState(0);

  const [cities, setCities] = useState([]);
  const [query, setQuery] = useState('');
  const [cityFilter, setCityFilter] = useState('');
  const [allData, setAllData] = useState({});

  const navigate = useNavigate();

  // setup data for every table
  const [dataTable1, setDataTable1] = useState([]);
  const [dataTable2, setDataTable2] = useState([]);

  const [branches, setBranches] = useState([]);

  const [pendingStudent, setPendingStudent] = useState(false)

  // pagination setup
  const resultsPerPage = 10;
  const totalResults = response.length;

  // pagination change control
  function onPageChangeTable1(p) {
    setPageTable1(p);
  }

  // pagination change control
  function onPageChangeTable2(p) {
    setPageTable2(p);
  }
  async function getAllList() {
    let data;
    const filters = {
      'asc': { "sort": "asc" },
      'dec': { "sort": "dec" },
      'pending': { "status": "Pending" },
      'rejected': { "status": 'Rejected' },
      'approved': { "status": 'Accepted' },
      'All': {},
      '': {}
    }
    console.log(cityFilter)
    data = await axios.post(config.API_URL + '/students/list', filters[query]);
    setTableBody(data.data);
  }

  async function handleAction(data, action) {
    await axios.patch(config.API_URL + '/students/' + data.id, { "status": action })
    getAllList()
  }

  async function getAllBranches() {
    let data;
    data = await axios.post(config.API_URL + '/branches/list', {});
    setBranches(data.data);
  }

  const handleChangeWithName = (value, name) => {
    console.log(value);
    setAllData({ ...allData, [name]: value });
    console.log(allData);
   
    // ToDo
    // setAllData({ ...allData, ['courseId']: courseId });

    // console.log(allData);
  };

  async function submit() {
    let data;
    data = await axios.post(config.API_URL + '/student-health-result', allData).then((res) => {
      if (res.status === 'failed' || res.data.failed === 'failed') {
        alert('Missing Fields.')
        return
      }
      getAllList()
    }).catch((err) => {
      alert('Missing Fields..')
    });
  }
  
  async function updatePassword(user){
    try {
      let password = allData.newPassword ;
      let rePassword = allData.confirmPassword;
      //console.log('pass: ',password,rePassword);
      if(password !== rePassword){
       setCheckPassword(true)
        return;
      }
      setCheckPassword(false)
      let resetData = {
        userId: user.userId,
        password,
        rePassword,
      }
     let  res = await axios.post(config.API_URL + '/users/update-password',resetData);
     if (res.data && (res.status === 200 || res.status === 201)) {
      // success => password updated
      setPasswordChanged(true)
      // setTimeout(() => {
      //   closeModal()
      // }, 1000)
      return
      }
    // failed  
    // setPendingStudent(false);
    } catch (error) {
      // error  
    }
  }

  async function checkStudentTransfer(user) {
    try {
      let res;
      // console.log('pendingStudent: ',pendingStudent)
      res = await axios.post(config.API_URL + '/students/transfer-by-studentId/' + user.id);
      // console.log('res.data.status: ',res)
      if (res.data && (res.status === 200 || res.status === 201)) {
        let branches = res?.data?.branch;
        // console.log('branches:', branches)
        if (branches.length > 0) { // can not add req
          setPendingStudent(true);
          // console.log('pendingStudentsss: ',pendingStudent)
          return;
        }
      }
      setPendingStudent(false);

    } catch (error) {
      console.log('checkStudentTransfer error: ',error)
      setPendingStudent(false);
    }
  }

  async function submitTransfer() {
    let data;
    let finalData = {};
    finalData.studentId = studentInfo.id
    finalData.old = studentInfo?.branchId;
    finalData.new = allData.new;
    finalData.type = 'Branch'
    console.log('allData: ', allData)
    console.log('finalData: ', finalData)
    data = await axios.post(config.API_URL + '/students/student-transfer', finalData).then((res) => {
      if (res.status === 'failed' || res.data.failed === 'failed') {
        alert('Missing Fields.')
        return
      }
      closeTransferModal()
      // getAllList()
    }).catch((err) => {
      alert('Missing Fields..')
    });
  }



  useEffect(() => {
    getAllList();
    getAllBranches();
  }, [query]);

  useEffect(() => {
    setDataTable1(
      response.slice(
        (pageTable1 - 1) * resultsPerPage,
        pageTable1 * resultsPerPage,
      ),
    );
  }, [pageTable1]);

  useEffect(() => {
    setDataTable2(
      response2.slice(
        (pageTable2 - 1) * resultsPerPage,
        pageTable2 * resultsPerPage,
      ),
    );
  }, [pageTable2]);

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="p-4 pt-0">

          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <ModalHeader>Reset Password</ModalHeader>
            <ModalBody>
              <p className='font-bold my-3'>Student Information</p>

              <div className='bg-[#F7F7F7] p-3 border-b border-black'>

                {/* {currentStudent?.map((student) => ( */}
                <div className='grid grid-cols-3 gap-7'>
                  <div className='flex gap-1'>
                    <img className='mt-0.5' style={{ width: '14px', height: '13px' }} src={MailIcon} />
                    <h1 className='text-[#046F6D] text-xs'>{studentInfo?.email}</h1>
                  </div>
                  <div className='flex gap-4'>
                    <img className='mt-0.5' style={{ width: "10.5px", height: "15px" }} src={LocationIcon} />
                    <p className='text-[#534E59]'>{studentInfo?.city?.nameEn}</p>
                  </div>
                  <div className='flex gap-2'>
                    <img className='mt-0.5' style={{ width: "15px", height: "16.5px" }} src={CalenderToday} />
                    <p className='text-[#534E59]'>Joined {moment(studentInfo?.createdAt).format('DD MMM YYYY')}</p>
                  </div>
                </div>
                {/* // ))} */}
              </div>



              <br />
              <div className=''>
                <div className="">

                  <div className="div-dropdown-wizard w-full">
                    <input className='w-full p-1' type="password" placeholder="New Password" onChange={(e) => handleChangeWithName(e.target.value, 'newPassword')} />
                  </div>
                </div>

                <div className="">

                  <div className="div-dropdown-wizard">
                    <input className='w-full p-1' type="password" placeholder="Confirm Password" onChange={(e) => handleChangeWithName(e.target.value, 'confirmPassword')} />
                  </div>
                  <>
                  {checkPassword === true ?
                  <span className='text-red-400'>Password and Re-Password does not match.</span>
                  :
                  <></>
                  }
                  </>
                </div>

                {passwordChanged == true ?
                <span className='text-green-600'>Password has been successfuly Changed</span> 
                :
                <></>
                }



              </div>


            </ModalBody>
            <ModalFooter>
              <Button className="w-full sm:w-auto" layout="outline" onClick={closeModal}>
                Cancel
              </Button>
              <Button onClick={() => {
                updatePassword(studentInfo)
                
              }} className="w-full sm:w-auto">Reset Password</Button>
            </ModalFooter>
          </Modal>

          {/* Add to corporate */}
          <Modal isOpen={isAddModalOpen} onClose={closeAddModal}>
            <ModalHeader>Add Student To Corporate</ModalHeader>
            <ModalBody>
              <p className='font-bold my-3'>Student Information</p>

              <div className='bg-[#F7F7F7] p-3 border-b border-black'>

                {/* {currentStudent?.map((student) => ( */}
                <div className='grid grid-cols-3 gap-3'>
                  <div className='flex gap-1'>
                    <img className='mt-0.5' style={{ width: '14px', height: '13px' }} src={MailIcon} />
                    <h1 className='text-[#046F6D] text-xs'>{studentInfo?.email}</h1>
                  </div>
                  <div className='flex gap-4'>
                    <img className='mt-0.5' style={{ width: "10.5px", height: "15px" }} src={LocationIcon} />
                    <p className='text-[#534E59]'>{studentInfo?.city?.nameEn}</p>
                  </div>
                  <div className='flex gap-2'>
                    <img className='mt-0.5' style={{ width: "15px", height: "16.5px" }} src={CalenderToday} />
                    <p className='text-[#534E59]'>Joined {moment(studentInfo?.createdAt).format('DD MMM YYYY')}</p>
                  </div>
                </div>
                {/* // ))} */}
              </div>



              <br />
              <div className=''>

                <div className="">
                  <div className="mt-3 h-6 text-xs font-bold uppercase leading-8 text-gray-500">
                    Resultss
                  </div>
                  <div className="div-dropdown-wizard">
                    <select
                      name="traineeType"
                      onChange={(e) => {
                        handleChangeWithName(e.target.value, 'result');
                        // console.log(e.target.value);
                      }}
                      className="select-dropdown-wizard "
                    >
                      <option value="" disabled selected>
                        {/* {studentInfo?.branch?.name} */}
                        Resultss
                      </option>

                    </select>
                  </div>
                </div>
              </div>


            </ModalBody>
            <ModalFooter>
              <Button className="w-full sm:w-auto" layout="outline" onClick={closeModal}>
                Cancel
              </Button>
              <Button onClick={() => {

                submit()
              }} className="w-full sm:w-auto">Upload Result</Button>
            </ModalFooter>
          </Modal>


          {/* Transfer student */}
          <Modal isOpen={isTransferModalOpen} onClose={closeTransferModal}>
            <ModalHeader>Transfer Student</ModalHeader>
            <ModalBody>
              <p className='font-bold my-3'>Student Information</p>

              <div className='bg-[#F7F7F7] p-3 border-b border-black'>
                <div className='flex justify-between	'>
                  <div>
                    <h1>{studentInfo?.nameEn}</h1>
                  </div>
                  <div>
                    <h1>Student Code :{studentInfo?.id}</h1>
                  </div>
                </div>


                <div className='grid grid-cols-3 gap-7'>
                  {/* {console.log(studentInfo)} */}
                  <div className='flex gap-1'>
                    <img className='mt-0.5' style={{ width: '14px', height: '13px' }} src={MailIcon} />
                    <h1 className='text-[#046F6D] text-xs'>{studentInfo?.email}</h1>
                  </div>
                  <div className='flex gap-4'>
                    <img className='mt-0.5' style={{ width: "10.5px", height: "15px" }} src={LocationIcon} />
                    <p className='text-[#534E59]'>{studentInfo?.city?.nameEn}</p>
                  </div>
                  <div className='flex gap-2'>
                    <img className='mt-0.5' style={{ width: "15px", height: "16.5px" }} src={CalenderToday} />
                    <p className='text-[#534E59]'>Joined {moment(studentInfo?.createdAt).format('DD MMM YYYY')}</p>
                  </div>
                </div>

              </div>



              <br />
             {pendingStudent?
             <h1>Pending for approval </h1>
             :
             <div className=''>
             <div className="">

               <div className="div-dropdown-wizard bg-gray-300">
                 <select
                   disabled
                   className="select-dropdown-wizard bg-gray-300"
                 >
                   <option value="" disabled selected>
                     {studentInfo?.branch?.name}
                   </option>
                 </select>
               </div>
             </div>

             <div className="">
               <div className="div-dropdown-wizard">
                 <select
                   name="branch"
                   onChange={(e) => {
                     handleChangeWithName(e.target.value, 'new');
                   }}
                   className="select-dropdown-wizard "
                 >
                   <option value="" disabled selected>
                     Select Branch..
                   </option>

                   {branches.filter((br) => br.id !== studentInfo.branchId).map((branch) => (

                     <option key={branch.id} value={branch.id}>
                       {branch.name}
                     </option>
                   ))}
                 </select>
               </div>
             </div>
           </div>
           }


            </ModalBody>
            <ModalFooter>
              <Button className="w-full sm:w-auto" layout="outline" onClick={closeTransferModal}>
                Cancel
              </Button>
              {pendingStudent?
                <Button disabled className="w-full sm:w-auto">Transfer Student</Button>
              :
              
              <Button onClick={() => {

                submitTransfer()
              }} className="w-full sm:w-auto">Transfer Student</Button>
            }
            </ModalFooter>
          </Modal>



          <div className="flex justify-between">
            <img src={studentIcon} className="w-8" aria-hidden="true" />
            <PageTitle className="w-1/2"> Students</PageTitle>
            <div className="w-5/6"></div>
          </div>

          <Button
            onClick={() => {
              navigate('/admin/students/add-new');
            }}
            className="w-1/5 h-14"
            style={{
              backgroundColor: '#046F6D',
              float: 'right',
              marginRight: '15px',
              marginBottom: '15px',
            }}
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16 22.75H3C2.04 22.75 1.25 21.96 1.25 21V8C1.25 3.58 3.58 1.25 8 1.25H16C20.42 1.25 22.75 3.58 22.75 8V16C22.75 20.42 20.42 22.75 16 22.75ZM8 2.75C4.42 2.75 2.75 4.42 2.75 8V21C2.75 21.14 2.86 21.25 3 21.25H16C19.58 21.25 21.25 19.58 21.25 16V8C21.25 4.42 19.58 2.75 16 2.75H8Z"
                fill="white"
              />
              <path
                d="M15.5 12.75H8.5C8.09 12.75 7.75 12.41 7.75 12C7.75 11.59 8.09 11.25 8.5 11.25H15.5C15.91 11.25 16.25 11.59 16.25 12C16.25 12.41 15.91 12.75 15.5 12.75Z"
                fill="white"
              />
              <path
                d="M12 16.25C11.59 16.25 11.25 15.91 11.25 15.5V8.5C11.25 8.09 11.59 7.75 12 7.75C12.41 7.75 12.75 8.09 12.75 8.5V15.5C12.75 15.91 12.41 16.25 12 16.25Z"
                fill="white"
              />
            </svg>
            <span className="ml-2 font-bold">Add New Student</span>{' '}
          </Button>

          <div className="flex my-2">
            <div className="mx-2">
              <label className="text-gray-700 text-sm">SORT:</label>
              <select
                className="mx-1"
                onChange={(e) => {
                  setQuery(e.target.value)
                  getAllList()
                }}
              >
                <option value="asc">A-Z</option>
                <option value="dec">Z-A</option>
              </select>
            </div>
            <div>
              <label className="text-gray-700 text-sm">STATUS:</label>
              <select
                className="mx-1"
                onChange={(e) => {
                  setQuery(e.target.value)
                  getAllList()
                }}
              >
                <option value="All">ALL</option>
                <option value="pending">Pending</option>
                <option value="approved">Approved</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>

          <TableContainer className="mb-8">
            <Table>
              <TableHeader>
                <tr className="bg-[#F7F7F7]">
                  <TableCell>ID</TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Registeration Date</TableCell>
                  <TableCell>City</TableCell>
                  <TableCell>Status</TableCell>
                  {query == 'Accepted' ? (
                    <TableCell>Status</TableCell>
                  ) : (
                    <TableCell>ِActions</TableCell>
                  )}
                  <TableCell></TableCell>
                </tr>
              </TableHeader>
              <TableBody>
                {tableBody.map((user, i) => (
                  <TableRow key={i}>
                    <TableCell>{user.id}</TableCell>
                    <TableCell>
                      <div className="flex items-center text-sm">
                        <div>
                          <p className="font-semibold">{user.nameEn}</p>
                        </div>
                      </div>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{user.email}</span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">
                        {moment(user.createdAt).format('DD MMM YYYY')}
                      </span>
                    </TableCell>
                    <TableCell>
                      <span className="text-sm">{user.city.nameEn}</span>
                    </TableCell>
                    <TableCell>
                      {user.status != 'Pending' ?
                        <Badge type={user.status === "Rejected" ? `danger` : 'success'}>{user.status}</Badge>
                        :
                        <>
                          <Button
                            style={{ backgroundColor: '#046F6D' }}
                            className="text-white mr-3"
                            onClick={() => {
                              handleAction(user, 'Accepted')
                              getAllList()
                            }}
                          >
                            Accept
                          </Button>
                          <Button
                            style={{ backgroundColor: '#D80027' }}
                            className="bg-red-600 text-white"
                            onClick={() => {
                              handleAction(user, 'Rejected')
                              getAllList()
                            }}
                          >
                            Reject
                          </Button>
                        </>
                      }
                    </TableCell>
                    <TableCell>
                      <div className='flex items-center justify-between'>
                        <div className='cursor-pointer text-lg relative'
                          onClick={() => {
                            setShowTableItemMenu(prev => !prev);
                            setTableItemIndex(i);
                          }
                          }
                        >
                          <i className="uil uil-ellipsis-h"></i>
                          {/* Drop menu */}
                          {
                            showTableItemMenu && i === tableItemIndex &&
                            <div className='absolute top-6 right-0 bg-pure rounded-md py-2 px-1 flex flex-col gap-1 z-50 w-[200px]'
                              style={{
                                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px'
                              }}
                            >

                              <div onClick={() => navigate('/admin/students/profile/' + user.id)} className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                <img src={UserSquare} className="w-5" aria-hidden="true" />
                                <p>View</p>
                              </div>
                              {/* <div onClick={() => {
                                setStudentInfo(user);
                                setPasswordChanged(false);
                                openModal()
                                }} className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                <img src={ResetPasswordSVG} className="w-5" aria-hidden="true" />
                                <p>Reset Password</p>
                              </div> */}
                              {/* <div onClick={() => openAddModal()} className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                <img src={corporateSVG} className="w-5" aria-hidden="true" />
                                <p>Add To Corporate </p>
                              </div> */}
                              {/* <div
                                onClick={() => {
                                  setStudentInfo(user)
                                  openTransferModal();
                                  checkStudentTransfer(user);
                                }}
                                className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                <img src={TransferSVG} className="w-5" aria-hidden="true" />
                                <p>Transfer Student</p>
                              </div> */}



                              {/* <div  onClick={() => navigate('/admin/edit-branch/'+user.id)} className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                                        <i className="uil uil-edit text-base"></i>
                                                        <p>Edit</p>
                                                    </div> */}
                              {/* <div className='flex items-center gap-3 text-sm hover:bg-gray-100 py-2 rounded-md px-2'>
                                                        <i className="uil uil-repeat text-base"></i>
                                                        <p>Replace Student</p>
                                                    </div> */}
                            </div>
                          }
                        </div>
                      </div>
                      {/* <img className='cursor-pointer' src={more}  onClick={() => navigate('/admin/students/profile/' + user.id)}/> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TableFooter>
              <Pagination
                totalResults={tableBody.length}
                resultsPerPage={resultsPerPage}
                onChange={onPageChangeTable1}
                style={{ color: '#718096' }}
                label="Table navigation"
              />
            </TableFooter>
          </TableContainer>
        </div>
      </div>
    </>
  );
}

export default Student;
