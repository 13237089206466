import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import CourseImage from '../../assets/img/CourseImage.svg';
import clock from '../../assets/img/clock.svg';
import clockhistory from '../../assets/img/clock-history.svg';
import user from '../../assets/img/user.svg';
import book from '../../assets/img/book.svg';
import presonImage4 from '../../assets/img/presonImage4.svg';
import playList from '../../assets/img/fontisto_play-list.svg';
import video from '../../assets/img/video.png';
import stop from '../../assets/img/gg_play-pause-o.svg';
import play from '../../assets/img/ant-design_play-circle-filled.svg';
import locked from '../../assets/img/locked.svg';

import CourseDetails from '../../components/course-detail-coinfarm/CourseDetails';

function CourseDetailsConfirm() {
  const param = useParams();

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <h1 className="font-bold text-3xl">Book Course</h1>
        <div className="flex flex-wrap m-4 mt-8">
          <div className="w-full md:w-1/2">
            <CourseDetails />
          </div>
          <div className="w-full md:w-1/2">
            <div className="p-2 max-w-sm bg-gray-100 border border-gray-200 rounded-lg shadow-md   mt-5">
              <div className="flex">
                <div className="flex justify-between">
                  <img className="mr-3" src={playList} />
                  <h1 className="font-bold text-sm"> 30 lessons</h1>
                </div>

                <div className="ml-20">
                  <div>
                    <div className="flex">
                      <img src={clock} />
                      <p>1h 30m</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md   p-2 mt-5">
                <div className="mr-2 mt-1">1</div>
                <div className="flex">
                  <img src={stop} />
                  <h1 className="mt-1 ml-2"> Intro</h1>
                </div>
                <div className="ml-36 mt-1">
                  <h1>10:00 MIN</h1>
                </div>
              </div>

              <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md   p-2">
                <div className="mr-2 mt-3">2</div>
                <div className="flex">
                  <img src={play} />
                  <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                </div>
                <div className="ml-20 mt-1">
                  <h1>10:00 MIN</h1>
                </div>
              </div>

              <div className="mt-4 flex bg-white border border-gray-200 rounded-lg shadow-md   p-2">
                <div className="mr-2 mt-3">3</div>
                <div className="flex">
                  <img src={locked} />
                  <h1 className="mt-1 ml-2"> Basic introduction...</h1>
                </div>
                <div className="ml-20 mt-1">
                  <h1>10:00 MIN</h1>
                </div>
              </div>

              <div className="grid grid-cols-2 gap-3 mt-10 ml-5">
                <div>
                  <button className="bg-[#046F6D] text-white font-bold p-3 rounded-lg w-full">
                    {' '}
                    Book Now{' '}
                  </button>
                </div>

                <div className="flex mt-5 ml-5">
                  <h1 className="flex font-bold text-2xl">180 </h1>
                  <p className="flex mt-2">/SAR</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseDetailsConfirm;
