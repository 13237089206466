import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Stepper from '../../components/Stepper';
import StepperControl from '../../components/StepperControl';
import { UseContextProvider } from '../../context/StepperContext';

import CorporateDetails from '../../components/corporate-steps/CorporateDetails';
import GuarantorDetails from '../../components/corporate-steps/GuarantorDetails';
import Attachment from '../../components/corporate-steps/Attachment';
import FinalAdmin from '../../components/corporate-steps/AdminDone';

function NewCorporate() {
  const param = useParams();

  const [currentStep, setCurrentStep] = useState(1);
  const [otp, setOtp] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const steps = [
    'Corporate Details',
    'Guarantor Details',
    'Attachment',
    'DONE',
  ];

  const displayStep = (step) => {
    switch (step) {
      case 1:
        return <CorporateDetails />;
      case 2:
        return <GuarantorDetails />;
      case 3:
        return <Attachment />;
      case 4:
        return <FinalAdmin />;
      default:
    }
  };

  const navigate = useNavigate();

  const handleClick = (direction) => {
    let newStep = currentStep;

    direction === 'next' ? newStep++ : newStep--;
    // check if steps are within bounds
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  function handleOTP() {
    setShowModal(true);
  }

  return (
    <>
      <div className="bg-[#FFFFFF] m-8 border-sm">
        <div className="flex justify-center">
          <div className="absolute mx-auto pb-2 md:w-9/12">
            {/* Stepper */}
            <div className="rounded-xl bg-white">
              <div className="horizontal shadow-xl container mt-24">
                <Stepper steps={steps} currentStep={currentStep} />
              </div>

              <div className="my-10 p-10 bg-white border-t border-black">
                <UseContextProvider>
                  {displayStep(currentStep)}
                </UseContextProvider>
              </div>
            </div>

            {/* navigation button */}
            {currentStep !== steps.length && (
              <StepperControl
                handleClick={handleClick}
                currentStep={currentStep}
                steps={steps}
                otp={otp}
                handleOTP={handleOTP}
              />
            )}
          </div>
        </div>
      </div>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden 
            overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="w-1/3 my-6 mx-auto max-w-4xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                <h1 className="text-center text-3xl font-bold mt-12">
                  OTP Verification
                </h1>
                <p className="text-center mt-4 text-[#718096]">
                  Please enter one time OTP to verify your account
                </p>
                <p className="text-center mt-4 font-bold">
                  A code has been sent to mobile number: ********12
                </p>
                <div
                  id="otp"
                  class="flex flex-row justify-center text-center px-2 mt-5"
                >
                  <input
                    class="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="first"
                    maxlength="1"
                  />
                  <input
                    class="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="second"
                    maxlength="1"
                  />
                  <input
                    class="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="third"
                    maxlength="1"
                  />
                  <input
                    class="m-2 border h-10 w-10 text-center form-control rounded"
                    type="text"
                    id="fourth"
                    maxlength="1"
                  />
                </div>
                <p className="text-center mt-4 font-bold">
                  Don't Get The Code {'  '}
                  <span className="underline text-[#046F6D] decoration-[#046F6D]">
                    Resend It
                  </span>
                </p>
                <div className="flex items-center p-2 border-t border-solid border-slate-200 rounded-b m-12">
                  <button
                    className="text-white bg-[#046F6D] w-full h-12"
                    type="button"
                    onClick={() => {
                      setShowModal(false);
                      setOtp(true);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export default NewCorporate;
